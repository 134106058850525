import { create } from "zustand";
import { apiFetch } from "@/services/siniestroService";

interface NotificationState {
  unreadCount: number;
  unreadMentionsCount: number;
  setUnreadCount: (count: number) => void;
  setUnreadMentionsCount: (count: number) => void;
  startFetchingUnreadCount: (username: string) => void;
  stopFetchingUnreadCount: () => void;
  resetUnreadCount: () => void;
  fetchInitialUnreadCount: (username: string) => Promise<void>;
}

let intervalId: NodeJS.Timeout | null = null;

const useNotificationStore = create<NotificationState>((set, get) => ({
  unreadCount: 0,
  unreadMentionsCount: 0,

  setUnreadCount: (count) => {
    set({ unreadCount: count });
  },

  setUnreadMentionsCount: (count) => {
    set({ unreadMentionsCount: count });
  },

  startFetchingUnreadCount: (username) => {
    const fetchUnreadCount = async () => {
      if (!username) return;
      try {
        const [notificationsResponse, mentionsResponse] = await Promise.all([
          apiFetch(`/api/notificaciones/${username}/unread-count`, {
            headers: {
              "X-Background-Request": "true",
            },
          }),
          apiFetch(`/api/notificaciones/${username}/unread-mentions-count`, {
            headers: {
              "X-Background-Request": "true",
            },
          }),
        ]);

        if (notificationsResponse.ok && mentionsResponse.ok) {
          const notificationsData = await notificationsResponse.json();
          const mentionsData = await mentionsResponse.json();

          get().setUnreadCount(notificationsData.unreadCount);
          get().setUnreadMentionsCount(mentionsData.unreadCount);
        }
      } catch (error) {
        console.error("Error fetching unread counts:", error);
      }
    };

    fetchUnreadCount(); // Fetch inicial
    intervalId = setInterval(fetchUnreadCount, 60000); // Poll cada minuto
  },

  stopFetchingUnreadCount: () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  },

  resetUnreadCount: () =>
    set({
      unreadCount: 0,
      unreadMentionsCount: 0,
    }),

  fetchInitialUnreadCount: async (username) => {
    if (!username) return Promise.resolve();
    try {
      const [notificationsResponse, mentionsResponse] = await Promise.all([
        apiFetch(`/api/notificaciones/${username}/unread-count`, {
          headers: {
            "X-Background-Request": "true",
          },
        }),
        apiFetch(`/api/notificaciones/${username}/unread-mentions-count`, {
          headers: {
            "X-Background-Request": "true",
          },
        }),
      ]);

      if (notificationsResponse.ok && mentionsResponse.ok) {
        const notificationsData = await notificationsResponse.json();
        const mentionsData = await mentionsResponse.json();

        get().setUnreadCount(notificationsData.unreadCount);
        get().setUnreadMentionsCount(mentionsData.unreadCount);
      }
    } catch (error) {
      console.error("Error fetching initial unread notifications:", error);
    }
    return Promise.resolve();
  },
}));

export default useNotificationStore;
