import { useEffect, useState } from "react";
import { apiFetch } from "@/services/siniestroService";
import AsignacionesCard from "./AsignacionesCard";
import MensajesCard from "./MensajesCard";
import Spinner from "../ui/spinner";
import { useAuth } from "@/contexts/AuthContext";
import { RocketIcon } from "@radix-ui/react-icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import useNotificationStore from "@/stores/notificationStore";
import EstadosCard from "./EstadosCard";
import MencionesCard from "./MencionesCard";
import { motion, AnimatePresence } from "framer-motion";

interface Notificacion {
  id: number;
  usuario: number;
  fecha: string;
  mensaje: string;
  leido: string;
  tipo: string;
}

const NotificacionesPage: React.FC = () => {
  const [notificaciones, setNotificaciones] = useState<Notificacion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { username, roles } = useAuth();
  const { setUnreadCount, setUnreadMentionsCount } = useNotificationStore();
  const [currentPageAsignaciones, setCurrentPageAsignaciones] =
    useState<number>(1);
  const [totalAsignaciones, setTotalAsignaciones] = useState<number>(0);
  const [currentPageMensajes, setCurrentPageMensajes] = useState<number>(1);
  const [totalMensajes, setTotalMensajes] = useState<number>(0);
  const [currentPageEstados, setCurrentPageEstados] = useState<number>(1); // Añadido
  const [totalEstados, setTotalEstados] = useState<number>(0); // Añadido
  const [currentPageMenciones, setCurrentPageMenciones] = useState<number>(1); // Añadido
  const [totalMenciones, setTotalMenciones] = useState<number>(0); // Añadido
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(false);
  const pageSize = 10;
  const isLaCajaUser = roles.includes(16);
  const isAlejandro = roles.includes(2); // Asumiendo que el rol 2 es el de admin/Alejandro

  // Separar las notificaciones por tipo
  const asignaciones = notificaciones.filter(
    (notificacion) => notificacion.tipo === "asignacion"
  );
  const mensajes = notificaciones.filter(
    (notificacion) => notificacion.tipo === "mensaje"
  );
  const notificacionesEstado = notificaciones.filter(
    (notificacion) => notificacion.tipo === "estado"
  );
  const menciones = notificaciones.filter(
    (notificacion) => notificacion.tipo === "mencion"
  );

  // Función para filtrar notificaciones
  const filterNotifications = (notifications: Notificacion[]) => {
    if (showUnreadOnly) {
      return notifications.filter((notif) => notif.leido === "0");
    }
    return notifications;
  };

  // Aplicar el filtro a cada tipo de notificación
  const filteredAsignaciones = filterNotifications(asignaciones);
  const filteredMensajes = filterNotifications(mensajes);
  const filteredNotificacionesEstado =
    filterNotifications(notificacionesEstado);
  const filteredMenciones = filterNotifications(menciones);

  useEffect(() => {
    const fetchNotificaciones = async () => {
      if (!username) {
        setLoading(false);
        setError("No hay usuario logueado");
        return;
      }

      setLoading(true);

      try {
        let endpoints = [];

        if (isLaCajaUser) {
          endpoints.push(
            apiFetch(
              `/api/notificaciones/${username}/estado?offset=${
                (currentPageEstados - 1) * pageSize
              }&limit=${pageSize}`
            )
          );
        } else if (isAlejandro) {
          // Solo traer menciones para Alejandro
          endpoints.push(
            apiFetch(
              `/api/notificaciones/${username}/mencion?offset=${
                (currentPageMenciones - 1) * pageSize
              }&limit=${pageSize}`
            )
          );
        } else {
          // Si no es usuario de La Caja, traer asignaciones y mensajes
          endpoints = [
            apiFetch(
              `/api/notificaciones/${username}/asignacion?offset=${
                (currentPageAsignaciones - 1) * pageSize
              }&limit=${pageSize}`
            ),
            apiFetch(
              `/api/notificaciones/${username}/mensaje?offset=${
                (currentPageMensajes - 1) * pageSize
              }&limit=${pageSize}`
            ),
            apiFetch(
              `/api/notificaciones/${username}/mencion?offset=${
                (currentPageMenciones - 1) * pageSize
              }&limit=${pageSize}`
            ),
          ];
        }

        const responses = await Promise.all(endpoints);
        const results = await Promise.all(responses.map((res) => res.json()));

        if (isLaCajaUser) {
          const [estados] = results;
          setNotificaciones(estados.notificaciones);
          setTotalEstados(estados.total);
        } else if (isAlejandro) {
          const [menciones] = results;
          setNotificaciones(menciones.notificaciones);
          setTotalMenciones(menciones.total);
        } else {
          const [asignaciones, mensajes, menciones] = results;
          setNotificaciones([
            ...asignaciones.notificaciones,
            ...mensajes.notificaciones,
            ...menciones.notificaciones,
          ]);
          setTotalAsignaciones(asignaciones.total);
          setTotalMensajes(mensajes.total);
          setTotalMenciones(menciones.total);
        }
      } catch (error) {
        setError("Error al cargar notificaciones");
        console.error("Error fetching notificaciones:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotificaciones();
  }, [
    username,
    isLaCajaUser,
    isAlejandro,
    currentPageAsignaciones,
    currentPageMensajes,
    currentPageEstados,
    currentPageMenciones,
    pageSize,
  ]);

  const handleMarkAsRead = async (notificacionId: number, leido: boolean) => {
    try {
      // Primero, envía la actualización al backend
      const response = await apiFetch(`/api/notificaciones/${notificacionId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ leido }),
      });

      if (!response.ok) throw new Error("Error al actualizar la notificación");

      // Actualizar ambos contadores desde el backend
      const [countResponse, mentionsResponse] = await Promise.all([
        apiFetch(`/api/notificaciones/${username}/unread-count`),
        apiFetch(`/api/notificaciones/${username}/unread-mentions-count`),
      ]);

      if (countResponse.ok && mentionsResponse.ok) {
        const { unreadCount } = await countResponse.json();
        const { unreadCount: mentionsCount } = await mentionsResponse.json();
        setUnreadCount(unreadCount);
        setUnreadMentionsCount(mentionsCount);
      }
      // Encuentra la notificación que se está actualizando
      const notificacionActual = notificaciones.find(
        (n) => n.id === notificacionId
      );

      if (notificacionActual) {
        // Actualiza el total correspondiente según el tipo de notificación
        switch (notificacionActual.tipo) {
          case "mencion":
            setTotalMenciones((prev) => (leido ? prev - 1 : prev + 1));
            break;
          case "asignacion":
            setTotalAsignaciones((prev) => (leido ? prev - 1 : prev + 1));
            break;
          case "mensaje":
            setTotalMensajes((prev) => (leido ? prev - 1 : prev + 1));
            break;
          case "estado":
            setTotalEstados((prev) => (leido ? prev - 1 : prev + 1));
            break;
        }
      }

      // Actualiza las notificaciones locales
      setNotificaciones((prevNotificaciones) =>
        prevNotificaciones.map((notificacion) =>
          notificacion.id === notificacionId
            ? { ...notificacion, leido: leido ? "1" : "0" }
            : notificacion
        )
      );
    } catch (err) {
      console.error("Error al marcar como leído/no leído", err);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.2,
      },
    },
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  // si no hay notificaciones
  if (notificaciones.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500">No hay notificaciones</p>
      </div>
    );
  }

  return (
    <div className="w-full p-10">
      <Alert className="mb-4 bg-accent text-accent-foreground">
        <RocketIcon className="h-4 w-4" />
        <AlertTitle>
          ¡Bienvenido a la bandeja de entrada de notificaciones!
        </AlertTitle>
        <AlertDescription>
          {isLaCajaUser
            ? "Aquí verás los cambios de estado de los siniestros."
            : isAlejandro
              ? "Aquí verás las menciones en los comentarios."
              : "Dale click derecho a cada mensaje para marcar como leído, ir al siniestro, etc."}
        </AlertDescription>
      </Alert>
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Bandeja de entrada</h1>
        <div className="flex items-center gap-2">
          <label className="text-sm flex items-center">
            <input
              type="checkbox"
              checked={showUnreadOnly}
              onChange={(e) => setShowUnreadOnly(e.target.checked)}
              className="mr-2"
            />
            Mostrar solo no leídos
          </label>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={showUnreadOnly ? "unread" : "all"}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {isLaCajaUser ? (
            <EstadosCard
              notificaciones={filteredNotificacionesEstado}
              handleMarkAsRead={handleMarkAsRead}
              totalNotificaciones={
                showUnreadOnly
                  ? filteredNotificacionesEstado.length
                  : totalEstados
              }
              currentPage={currentPageEstados}
              onPageChange={setCurrentPageEstados}
            />
          ) : isAlejandro ? (
            <div className="w-full">
              <MencionesCard
                menciones={filteredMenciones}
                handleMarkAsRead={handleMarkAsRead}
                totalMenciones={
                  showUnreadOnly ? filteredMenciones.length : totalMenciones
                }
                currentPage={currentPageMenciones}
                onPageChange={setCurrentPageMenciones}
              />
            </div>
          ) : (
            <ResizablePanelGroup
              direction="horizontal"
              className="min-h-[200px] w-full rounded-lg border"
            >
              <ResizablePanel defaultSize={50}>
                <div className="flex h-full items-center justify-center p-6">
                  <div className="w-full">
                    <AsignacionesCard
                      asignaciones={filteredAsignaciones}
                      handleMarkAsRead={handleMarkAsRead}
                      totalAsignaciones={
                        showUnreadOnly
                          ? filteredAsignaciones.length
                          : totalAsignaciones
                      }
                      currentPage={currentPageAsignaciones}
                      onPageChange={setCurrentPageAsignaciones}
                    />
                  </div>
                </div>
              </ResizablePanel>
              <ResizableHandle withHandle />
              <ResizablePanel defaultSize={50}>
                <div className="flex h-full items-center justify-center p-6">
                  <div className="w-full">
                    <MensajesCard
                      mensajes={filteredMensajes}
                      handleMarkAsRead={handleMarkAsRead}
                      totalMensajes={
                        showUnreadOnly ? filteredMensajes.length : totalMensajes
                      }
                      currentPage={currentPageMensajes}
                      onPageChange={setCurrentPageMensajes}
                    />
                  </div>
                </div>
              </ResizablePanel>
              <ResizableHandle withHandle />
              <ResizablePanel defaultSize={50}>
                <div className="flex h-full items-center justify-center p-6">
                  <div className="w-full">
                    <MencionesCard
                      menciones={filteredMenciones}
                      handleMarkAsRead={handleMarkAsRead}
                      totalMenciones={
                        showUnreadOnly
                          ? filteredMenciones.length
                          : totalMenciones
                      }
                      currentPage={currentPageMenciones}
                      onPageChange={setCurrentPageMenciones}
                    />
                  </div>
                </div>
              </ResizablePanel>
            </ResizablePanelGroup>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default NotificacionesPage;
