import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { SolarLogout2Bold } from "./icons/SolarLogout2Bold";
import { Button } from "./ui/button";
import { PhFirstAidKitBold } from "./icons/PhFirstAidKitBold";
import { MaterialSymbolsSettingsAccountBox } from "./icons/MaterialSymbolsSettingsAccountBox";
import { ModeToggle } from "./mode-toggle";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "./ui/dropdown-menu";
import {
  RiMailLine,
  RiNotification3Line,
  RiSearchLine,
  RiTimerLine,
} from "react-icons/ri";
import useNotificationStore from "@/stores/notificationStore";
import { FaStore } from "react-icons/fa";
import { HiChartPie } from "react-icons/hi";
import { motion } from "framer-motion";
import logoNavbar from "../assets/logonavbar.png";

const links = [
  {
    title: "Siniestros",
    href: "/siniestros",
    roles: [2, 3, 4, 5, 6, 7, 15, 12, 11, 16, 14, 13],
  },
  { title: "Búsqueda Stros.", href: "/siniestros/busqueda", roles: [7] },
  { title: "Parametría", href: "/list", roles: [2] },
  { title: "Facturación", href: "/facturacion", roles: [2] },
  { title: "Dashboard", href: "/dashboard", roles: [2] },
  { title: "Estadísticas", href: "/dashboard/stats", roles: [2, 7] },
  { title: "Proveedores", href: "/proveedores", roles: [2] },
  { title: "Stros. prescritos", href: "/siniestros/antiguos", roles: [2] },
];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { roles, isAuthenticated, logout, username } = useAuth();
  const {
    unreadCount,
    unreadMentionsCount,
    startFetchingUnreadCount,
    stopFetchingUnreadCount,
    resetUnreadCount,
    fetchInitialUnreadCount,
  } = useNotificationStore();
  const shouldShowMessages = !roles.some((role) => [7, 11].includes(role));

  useEffect(() => {
    if (username) {
      const cleanUsername = username.trim();

      fetchInitialUnreadCount(cleanUsername).catch((error) =>
        console.error("Error during initial fetch:", error)
      );

      startFetchingUnreadCount(cleanUsername);
    }

    return () => {
      stopFetchingUnreadCount();
    };
  }, [username]);

  const handleLogout = () => {
    logout();
    resetUnreadCount();
    navigate("/");
  };

  const handleLogin = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("state");
    localStorage.removeItem("roles");
    navigate("/");
  };

  const filteredLinks = links.filter((link) => {
    // Verificar si el usuario tiene alguno de los roles permitidos para el enlace
    return roles?.some((role) => link.roles.includes(role));
  });

  return (
    <div className="fixed top-0 w-full bg-opacity-60 backdrop-blur-md border-b border-gray-200 dark:border-gray-700 flex justify-between items-center px-4 py-2 z-10">
      <div className="flex items-center space-x-4">
        <Link
          to="/siniestros"
          className="flex items-center space-x-2 no-underline text-gray-800 dark:text-gray-200 hover:no-underline hover:text-gray-800 dark:hover:text-gray-200 focus:no-underline focus:text-gray-800 dark:focus:text-gray-200"
        >
          <motion.img
            src={logoNavbar}
            alt="Tu Service Logo"
            className="w-10 h-10 object-contain"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.5,
            }}
            whileHover={{
              scale: 1.1,
              rotate: 5,
              transition: { duration: 0.2 },
            }}
          />
          <motion.span
            className="text-sm font-semibold"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 20,
              delay: 0.2,
            }}
            whileHover={{
              scale: 1.05,
              color: "#3b82f6",
              transition: { duration: 0.2 },
            }}
          >
            Tu Service
          </motion.span>
        </Link>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <motion.div whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }}>
              <Button
                className="focus:outline-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                variant="default"
              >
                <motion.span
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 24,
                  }}
                >
                  Menú
                </motion.span>
              </Button>
            </motion.div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" asChild>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              {filteredLinks.map((link) => (
                <DropdownMenuItem key={link.title} asChild>
                  <Link
                    to={link.href}
                    className={`flex items-center no-underline text-gray-800 dark:text-gray-200 text-sm ${
                      location.pathname === link.href
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={(e) => {
                      if (location.pathname === link.href) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <motion.div
                      whileHover={{ x: 5 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="flex items-center w-full"
                    >
                      {getIcon(link.title)}
                      {link.title}
                    </motion.div>
                  </Link>
                </DropdownMenuItem>
              ))}
            </motion.div>
          </DropdownMenuContent>
        </DropdownMenu>
        <ModeToggle />
      </div>

      <div className="flex items-center space-x-4">
        {isAuthenticated ? (
          <>
            <motion.span
              className="hidden sm:flex items-center mr-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
                delay: 0.3,
              }}
            >
              Bienvenido, {username} <span className="ml-1 text-xl">👋</span>
            </motion.span>

            {/* Icono de notificaciones */}
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
                delay: 0.4,
              }}
            >
              <Link to="/notificaciones" className="relative inline-block">
                {unreadCount > 0 && (
                  <motion.span
                    className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-1.5 py-0.5 z-10"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 300,
                      damping: 20,
                    }}
                  >
                    {unreadCount}
                  </motion.span>
                )}
                <motion.div
                  initial={{ rotate: 0 }}
                  whileHover={{
                    rotate: [0, -10, 10, -10, 10, 0],
                    transition: {
                      duration: 0.5,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1],
                      repeat: 0,
                    },
                  }}
                >
                  <RiNotification3Line className="w-6 h-6 text-gray-800 dark:text-gray-200" />
                </motion.div>
              </Link>
            </motion.div>

            {/* Icono de mensajes */}
            {shouldShowMessages && (
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 20,
                  delay: 0.5,
                }}
              >
                <Link to="/notificaciones" className="relative inline-block">
                  {unreadMentionsCount > 0 && (
                    <motion.span
                      className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full px-1.5 py-0.5 z-10"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                    >
                      {unreadMentionsCount}
                    </motion.span>
                  )}
                  <motion.div
                    initial={{ y: 0 }}
                    whileHover={{
                      y: [0, -4, 0],
                      transition: {
                        duration: 0.5,
                        ease: "easeInOut",
                        times: [0, 0.5, 1],
                        repeat: 0,
                      },
                    }}
                  >
                    <RiMailLine className="w-6 h-6 text-gray-800 dark:text-gray-200" />
                  </motion.div>
                </Link>
              </motion.div>
            )}

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
                delay: 0.6,
              }}
            >
              <Button
                variant="link"
                asChild
                className="text-sm text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"
              >
                <a
                  href="https://tuservicesrl.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <FaStore className="mr-1" />
                  División Electro
                </a>
              </Button>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
                delay: 0.7,
              }}
            >
              <Button
                onClick={handleLogout}
                variant="destructive"
                className="flex items-center"
              >
                <span className="hidden sm:inline">Cerrar sesión</span>
                <SolarLogout2Bold className="w-4 h-4 ml-2" />
              </Button>
            </motion.div>
          </>
        ) : (
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 20,
            }}
          >
            <Button
              onClick={handleLogin}
              variant="default"
              className="flex items-center"
            >
              <span>Iniciar sesión</span>
            </Button>
          </motion.div>
        )}
      </div>
    </div>
  );
};

const getIcon = (title: string) => {
  switch (title) {
    case "Siniestros":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Parametría":
      return <MaterialSymbolsSettingsAccountBox className="mr-2" />;
    case "Dashboard":
      return <MaterialSymbolsSettingsAccountBox className="mr-2" />;
    case "Facturación":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Proveedores":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Stros. prescritos":
      return <RiTimerLine className="mr-2" />;
    case "Estadísticas":
      return <HiChartPie className="mr-2" />; // Nuevo ícono
    case "Búsqueda Stros.":
      return <RiSearchLine className="mr-2" />;
    default:
      return null;
  }
};

export default Navbar;
