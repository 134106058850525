import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from "@/components/ui/select";
import { fetchParentableCompanies } from "@/services/companyService";
import { useEffect, useState } from "react";

interface CompanyFilterProps {
  onChange: (value: string | undefined) => void;
  selectedValue?: string;
}

export const CompanyFilter: React.FC<CompanyFilterProps> = ({
  selectedValue,
  onChange,
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    const fetchAndSetCompanies = async () => {
      const fetchedCompanies = await fetchParentableCompanies();
      setCompanies(fetchedCompanies);
    };

    fetchAndSetCompanies();
  }, []);

  const selectValue = selectedValue || "all";

  return (
    <Select
      value={selectValue}
      onValueChange={(value) => {
        const newValue = value === "all" ? undefined : value;
        onChange(newValue);
      }}
    >
      <SelectTrigger className="w-[130px]">
        <div className="w-[90px] overflow-hidden text-ellipsis whitespace-nowrap block truncate text-left">
          {companies.find((company) => company.id.toString() === selectedValue)
            ?.name || "Compañías"}
        </div>
      </SelectTrigger>
      <SelectContent className="w-[200px]">
        <SelectGroup className="overflow-y-auto max-h-[25rem]">
          <SelectLabel>Compañías</SelectLabel>
          <SelectItem key="all" value="all" className="truncate pr-2">
            Todas
          </SelectItem>
          {companies.map((company) => (
            <SelectItem
              key={company.id}
              value={company.id.toString()}
              className="truncate pr-2"
            >
              {company.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
