import React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface DateFilterProps {
  selectedStartYear: number;
  selectedStartMonth: number;
  selectedEndYear: number;
  selectedEndMonth: number;
  onStartYearChange: (value: string) => void;
  onStartMonthChange: (value: string) => void;
  onEndYearChange: (value: string) => void;
  onEndMonthChange: (value: string) => void;
  onPeriodChange: (value: string) => void;
  isMercantilAndina?: boolean;
}

const DateFilter: React.FC<DateFilterProps> = ({
  selectedStartYear,
  selectedStartMonth,
  selectedEndYear,
  selectedEndMonth,
  onStartYearChange,
  onStartMonthChange,
  onEndYearChange,
  onEndMonthChange,
  onPeriodChange,
  isMercantilAndina = false,
}) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;


  // Modificar el array de años basado en la restricción
  const startYear = isMercantilAndina ? 2024 : currentYear - 9;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  ).filter((year) => !isMercantilAndina || year >= 2024);


  const months = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
  ];

  // Filtrar períodos disponibles
  const periods = [
    { value: "custom", label: "Personalizado" },
    { value: "currentMonth", label: "Mes Actual" },
    { value: "lastMonth", label: "Mes Anterior" },
    { value: "currentQuarter", label: "Trimestre Actual" },
    { value: "lastQuarter", label: "Trimestre Anterior" },
    { value: "currentYear", label: "Año Actual" },
    ...(!isMercantilAndina || currentYear > 2024
      ? [{ value: "lastYear", label: "Año Anterior" }]
      : []),
  ];

  return (
    <div className="flex flex-col space-y-2">
      <Select
        defaultValue="custom"
        onValueChange={(value) => {
          if (isMercantilAndina) {
            const now = new Date();
            const currentYear = now.getFullYear();
            if (value === "lastYear" && currentYear - 1 < 2024) {
              return;
            }
          }
          onPeriodChange(value);
        }}
      >
        <SelectTrigger className="w-[200px]">
          <SelectValue placeholder="Seleccionar período" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {periods.map((period) => (
              <SelectItem key={period.value} value={period.value}>
                {period.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <div className="flex space-x-4">
        <div className="flex space-x-2">
          <Select
            value={selectedStartYear.toString()}
            onValueChange={(value) => {
              const newYear = parseInt(value);
              // Si el año inicial es mayor que el año final, actualizar el año final
              if (newYear > selectedEndYear) {
                onEndYearChange(value);
                // Si los meses no son válidos, actualizar el mes final
                if (selectedStartMonth > selectedEndMonth) {
                  onEndMonthChange(selectedStartMonth.toString());
                }
              }
              onStartYearChange(value);
            }}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Año inicial" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {years.map((year) => (
                  <SelectItem key={year} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={selectedStartMonth.toString()}
            onValueChange={(value) => {
              const newMonth = parseInt(value);
              // Si estamos en el mismo año y el mes inicial es mayor que el mes final
              if (
                selectedStartYear === selectedEndYear &&
                newMonth > selectedEndMonth
              ) {
                onEndMonthChange(value);
              }
              onStartMonthChange(value);
            }}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Mes inicial" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {months.map((month) => (
                  <SelectItem
                    key={month.value}
                    value={month.value.toString()}
                    disabled={
                      selectedStartYear === currentYear &&
                      month.value > currentMonth
                    }
                  >
                    {month.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center">hasta</div>

        <div className="flex space-x-2">
          <Select
            value={selectedEndYear.toString()}
            onValueChange={(value) => {
              const newYear = parseInt(value);
              // No permitir seleccionar un año final anterior al año inicial
              if (newYear < selectedStartYear) {
                return;
              }
              onEndYearChange(value);
            }}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Año final" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {years
                  .filter((year) => year >= selectedStartYear)
                  .map((year) => (
                    <SelectItem key={year} value={year.toString()}>
                      {year}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={selectedEndMonth.toString()}
            onValueChange={(value) => {
              const newMonth = parseInt(value);
              // No permitir seleccionar un mes final anterior al mes inicial si estamos en el mismo año
              if (
                selectedStartYear === selectedEndYear &&
                newMonth < selectedStartMonth
              ) {
                return;
              }
              onEndMonthChange(value);
            }}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Mes final" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {months.map((month) => (
                  <SelectItem
                    key={month.value}
                    value={month.value.toString()}
                    disabled={
                      (selectedEndYear === currentYear &&
                        month.value > currentMonth) ||
                      (selectedEndYear === selectedStartYear &&
                        month.value < selectedStartMonth)
                    }
                  >
                    {month.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
