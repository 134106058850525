/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable new-cap */
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { buildAddressString } from "../SiniestroDetails/SiniestroDetails";
import logoTS from "../../../assets/logoremitos.png";
import { apiFetch } from "@/services/siniestroService";

async function generateAndUpdateDeliveryNumber(selectedBienesIds: number[]) {
  const response = await apiFetch(`/api/bienes/delivery-number/batch`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ bienIds: selectedBienesIds }),
  });
  const data = await response.json();
  return data;
}

export const exportRemitoEntregaRechazoToPDF = async (
  siniestro: Siniestro,
  selectedBienes: Bien[]
) => {
  const selectedBienesIds = selectedBienes.map((bien) => bien.id);
  const deliveryNumber =
    await generateAndUpdateDeliveryNumber(selectedBienesIds);

  await new Promise<void>((resolve, reject) => {
    try {
      const doc = new jsPDF();
      doc.addImage(logoTS, "PNG", 0, 0, 60, 60);

      // Establece un margen debajo del logo para empezar a escribir el texto
      const logoHeight = 60;
      const spaceBelowLogo = 0;
      const textBelowLogoY = logoHeight + spaceBelowLogo;

      // Establece el tamaño de fuente para el texto debajo del logo
      doc.setFontSize(10); // Cambia el tamaño de fuente como sea necesario

      // Añade el texto debajo del logo
      const textBelowLogo = "Fraga 201 - (1427) C.A.B.A.";
      doc.text(textBelowLogo, 9, textBelowLogoY);

      let currentY = 70;
      const labelFontSize = 7; // Tamaño de fuente para las etiquetas
      const valueFontSize = 10; // Tamaño de fuente para los valores
      const margin = 10; // Margen externo
      const pageWidth = 210; // Ancho total de la página
      const maxWidth = pageWidth - 2 * margin; // Ancho máximo para el contenido
      const lineHeight = 5; // Altura de la línea de texto

      // Define los pares de etiquetas y valores
      const info = [
        [
          "COMPANÍA, NRO. DE SINIESTRO Y NRO. DE PÓLIZA",
          siniestro.companyName +
            " | Nº " +
            siniestro.companyIncidentNumber +
            " | Póliza Nº " +
            siniestro.policyNumber,
        ],
        ["TIPO Y NRO. DE DOCUMENTO", "DNI " + siniestro.insuredDNI],
        ["NOMBRE DEL ASEGURADO", siniestro.insuredName],
        ["CELULAR", siniestro.occurrenceCellphone],
        ["DIRECCIÓN", buildAddressString(siniestro)],
        ["TELÉFONO", siniestro.occurrenceTelephone],
      ];

      // Cálculo del ancho de las columnas basado en la longitud del texto
      const leftColumnWidth = maxWidth * 0.7; // 70% del ancho disponible para la columna izquierda
      const rightColumnWidth = maxWidth * 0.3; // 30% del ancho disponible para la columna derecha

      // Inicio de la sección adicional
      const sectionX = 15; // El inicio de la X después del logo, ajusta según sea necesario
      const sectionWidth = pageWidth - sectionX - margin;

      // Dibuja la X dentro del rectángulo
      doc.setFontSize(40); // Tamaño grande para la X
      doc.text("X", sectionX + sectionWidth / 2, 30, { align: "center" });

      // Asegúrate de que estás usando la alineación 'center' para el texto debajo de la 'X'
      doc.setFontSize(7); // Tamaño de fuente más pequeño para este texto
      doc.text(
        "Documento no válido como factura",
        sectionX + sectionWidth / 2,
        35,
        { align: "center" }
      );

      // Alineación derecha para "REMITO DE ENTREGA Nº" y número de orden
      const rightStartX = pageWidth - margin; // Comienza en el borde derecho de la página menos el margen

      // Ajusta la posición Y para alinearlo con el logo y la "X"
      const orderLabelY = 10; // Alineación vertical con el logo y la "X"

      // Calcula la posición X para alinear "REMITO DE ENTREGA Nº" con el número de orden
      const orderLabel = "REMITO DE ENTREGA Nº " + deliveryNumber;
      doc.setFontSize(13); // Tamaño de fuente para el texto de la orden de trabajo
      const orderLabelWidth = doc.getStringUnitWidth(orderLabel) * 10; // Ancho del texto
      doc.text(orderLabel, rightStartX - orderLabelWidth + 150, orderLabelY, {
        align: "right",
      });

      // Solo muestra el remito de retiro si existe
      const receiptNumber = selectedBienes[0]?.receiptNumber;
      if (receiptNumber) {
        const deliveryLabel = "REMITO DE RETIRO Nº " + receiptNumber;
        doc.setFontSize(12);
        const deliveryLabelWidth = doc.getStringUnitWidth(deliveryLabel) * 10;
        doc.text(
          deliveryLabel,
          rightStartX - deliveryLabelWidth + 140,
          orderLabelY + lineHeight,
          { align: "right" }
        );
      }

      // Dibuja los cuadros para la fecha debajo de "REMITO DE ENTREGA Nº"
      const boxWidth = 10; // Ancho de cada cuadro para la fecha
      const totalBoxesWidth = 3 * boxWidth; // Ancho total de los 3 cuadros
      const boxStartX = rightStartX - totalBoxesWidth; // Ajusta para que los cuadros queden alineados a la derecha
      const boxY = orderLabelY + lineHeight + 8; // Posición Y debajo del texto "ORDEN DE TRABAJO Nº"

      // Calcula la posición X para centrar "FECHA" sobre los cuadros
      const fechaLabel = "FECHA";
      doc.setFontSize(10); // Tamaño de fuente para el texto "FECHA"
      const fechaLabelWidth = doc.getStringUnitWidth(fechaLabel) * 10; // Ancho del texto "FECHA"
      const fechaLabelX =
        boxStartX + totalBoxesWidth / 2 - fechaLabelWidth / 2 + 10; // Ajusta para centrar el texto "FECHA"
      doc.text(fechaLabel, fechaLabelX, boxY - 3); // Posiciona "FECHA" encima de los cuadros

      // Dibuja cada cuadro para la fecha sin espacio entre ellos
      for (let i = 0; i < 3; i++) {
        doc.rect(boxStartX + i * boxWidth, boxY, boxWidth, 10); // Dibuja cada cuadro
      }

      // Alinea los textos adicionales debajo de los cuadros
      const additionalTexts = [
        "C.U.I.T.: 30-71450473-4",
        "Ing. Brutos: 1275972",
        "Inicio de actividades: 06/2014",
        "At. al Cliente: 4551-7771 Líneas Rotativas",
        "Lunes a viernes 10 a 13 hs - 14 a 17 hs.",
      ];
      let additionalTextY = boxY + 15; // Posición Y inicial para los textos adicionales
      additionalTexts.forEach((text) => {
        doc.text(text, rightStartX, additionalTextY, { align: "right" });
        additionalTextY += 5; // Incrementa para el siguiente texto
      });

      doc.setFontSize(7); // Tamaño de fuente para el texto de la orden de trabajo
      doc.text(
        "TU SERVICE S.R.L. entrega por cuenta y orden de la Compañía de seguros, los siguientes bienes según detalle:",
        margin,
        currentY
      );

      // agregar espacio después del texto
      currentY += 5;

      for (let i = 0; i < info.length; i += 2) {
        doc.setFontSize(valueFontSize);
        const leftValueLines = doc.splitTextToSize(info[i][1], leftColumnWidth);
        // Reducir el multiplicador de lineHeight y/o reducir el valor adicional
        const leftRectHeight = leftValueLines.length * lineHeight + 8; // Reducido de +10 a +5

        let rightRectHeight = 0; // Declara la altura del rectángulo derecho

        if (i + 1 < info.length) {
          const rightValueLines = doc.splitTextToSize(
            info[i + 1][1],
            rightColumnWidth
          );
          // Aplicar el mismo ajuste aquí si necesario
          rightRectHeight = rightValueLines.length * lineHeight + 2;
        }

        const rowHeight = Math.max(leftRectHeight, rightRectHeight);

        // Dibuja los rectángulos y el texto de la izquierda
        doc.setFontSize(labelFontSize);
        doc.setFillColor(255, 255, 255);
        doc.rect(margin, currentY, leftColumnWidth, rowHeight, "FD");
        doc.text(info[i][0], margin + 2, currentY + lineHeight);

        doc.setFontSize(valueFontSize);
        doc.text(leftValueLines, margin + 2, currentY + 2 * lineHeight);

        if (i + 1 < info.length) {
          // Asegúrate de que el ancho de la columna derecha es el correcto
          const rightValueLines = doc.splitTextToSize(
            info[i + 1][1],
            rightColumnWidth - margin
          );
          const rightRectHeight =
            (rightValueLines.length + 1) * lineHeight + 10;

          // Ajusta la posición x del rectángulo derecho para que no haya espacio entre ellos
          const rightRectX = margin + leftColumnWidth;

          doc.setFontSize(labelFontSize);
          doc.setFillColor(255, 255, 255);
          // Usa rightRectX para la posición x y asegúrate de que no hay espacio entre los rectángulos
          doc.rect(
            rightRectX,
            currentY,
            rightColumnWidth,
            rightRectHeight - 7,
            "FD"
          );
          doc.text(info[i + 1][0], rightRectX + 2, currentY + lineHeight);

          doc.setFontSize(valueFontSize);
          doc.text(rightValueLines, rightRectX + 2, currentY + 2 * lineHeight);
        }

        // Ajusta currentY para el próximo par de rectángulos
        currentY += rowHeight;
      }

      // Añade la tabla de bienes
      currentY += 10; // Añade un espacio entre la información y la tabla

      const tableStyles = {
        startY: currentY, // La posición Y inicial para la tabla
        margin: { top: 10, right: 10, bottom: 10, left: 10 }, // Margenes alrededor de la tabla
        styles: { fontSize: 10 }, // Tamaño de fuente global para la tabla
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 30 },
        },
        theme: "grid", // Estilo de la tabla
      };

      // Añade los datos de bienes a la tabla
      const tableBody = selectedBienes.map((bien) => [
        bien.itemType || "",
        bien.brandName || "",
        bien.model || "",
        bien.serial || "",
        bien.accesories || "",
      ]);

      // Crea la tabla con autotable
      autoTable(doc, {
        head: [["EQUIPO", "MARCA", "MODELO", "SERIE", "ACCESORIOS"]],
        body: tableBody,
        ...tableStyles,
        theme: "grid",
      });

      // Finaliza la tabla de bienes y guarda la posición Y final
      currentY = (doc as any).lastAutoTable.finalY + 10;

      // Añade un espacio después de la tabla antes de poner más texto
      const spaceAfterTable = 1;
      currentY = currentY + spaceAfterTable;

      // Agrega un texto debajo de la tabla
      const additionalText =
        "Los equipos detallados no han sido reparados en relación a los daños que se denunciaran en la póliza de referencia.";
      const additionalTextLines = doc.splitTextToSize(additionalText, maxWidth); // Ajusta el texto al ancho máximo
      const lineHeightSmallText = 3; // Altura de línea para el texto pequeño
      // Imprime cada línea del texto adicional
      additionalTextLines.forEach((line: string | string[]) => {
        doc.setFontSize(labelFontSize);
        doc.text(line, margin, currentY);
        currentY += lineHeightSmallText; // Añade la altura de línea para la siguiente línea de texto
      });

      // Añadir espacio luego del texto adicional

      currentY += 7; // Añade un espacio después del texto adicional

      // Dibuja un rectángulo para el área de "OBSERVACIONES"
      const observationsFieldHeight = 25; // Ajusta esto según la cantidad de texto que esperas
      doc.rect(margin, currentY, maxWidth, observationsFieldHeight);

      // Agrega el título 'OBSERVACIONES' dentro del rectángulo
      const observationsFieldLabel = "* Por desestimiento llene aquí:";
      doc.setFontSize(labelFontSize); // Tamaño de fuente para las etiquetas
      const textYPosition = currentY + 5; // Alinea el texto con el centro del rectángulo
      doc.text(observationsFieldLabel, margin + 2, textYPosition);

      // Añade líneas sólidas para escritura
      let lineYPosition = textYPosition + lineHeight; // Comienza un poco más abajo del título
      const lineSpacing = 7; // Aumenta el espaciado entre líneas
      // Calcula cuántas líneas caben en el espacio dado
      const numberOfLines = Math.floor(
        (observationsFieldHeight - lineHeight) / lineSpacing
      );

      for (let i = 0; i < numberOfLines; i++) {
        doc.line(
          margin + 2,
          lineYPosition,
          margin + maxWidth - 2,
          lineYPosition
        ); // Dibuja la línea
        lineYPosition += lineSpacing;
      }

      // Añade espacio después del área de observaciones
      currentY += observationsFieldHeight;

      // Define la altura necesaria para la sección de firma y aclaración
      const signatureAreaHeight = 20; // Puedes ajustar este valor según tus necesidades

      // Verifica si el contenido excede la altura de la página antes de agregar la firma y aclaración
      const pageHeight = 297; // Altura de una hoja A4 en milímetros
      const bottomMargin = 10; // Define un margen inferior para no escribir hasta el borde de la página

      // Verifica si necesitas añadir una nueva página
      if (currentY + signatureAreaHeight + bottomMargin > pageHeight) {
        doc.addPage(); // Añade una nueva página
        currentY = margin; // Restablece currentY al margen superior de la nueva página
      }

      // Asegúrate de que tienes suficiente espacio en currentY para la firma y aclaración
      currentY += 10;

      // Calcula el ancho disponible para la firma y la aclaración
      const availableWidth = pageWidth - 2 * margin;
      const halfWidth = availableWidth / 2;

      // Línea de firma
      doc.setFontSize(10);
      const signatureLabel = "Firma asegurado:";
      doc.text(signatureLabel, margin, currentY);

      // Calcula el inicio de la línea de firma para que quede a la derecha de la etiqueta 'Firma'
      const signatureLabelWidth = doc.getTextWidth(signatureLabel);
      doc.setLineWidth(0.5);
      doc.line(
        margin + signatureLabelWidth + 10,
        currentY,
        margin + halfWidth - 5,
        currentY
      ); // Ajusta los márgenes según sea necesario

      // Línea de aclaración
      const clarificationLabel = "Firma Tu Service:";
      // Coloca la etiqueta 'Aclaración' después de la mitad del espacio disponible
      doc.text(clarificationLabel, margin + halfWidth, currentY);

      // Calcula el inicio de la línea de aclaración para que quede a la derecha de la etiqueta 'Aclaración'
      const clarificationLabelWidth = doc.getTextWidth(clarificationLabel);
      doc.line(
        margin + halfWidth + clarificationLabelWidth + 10,
        currentY,
        pageWidth - margin - 5,
        currentY
      ); // Ajusta los márgenes según sea necesario

      // ... (código restante para guardar o mostrar el PDF)

      doc.save("remito_siniestro.pdf");

      resolve(); // Resuelve la promesa cuando el PDF se ha generado y guardado correctamente
    } catch (error) {
      reject(error);
    }
  });
};
