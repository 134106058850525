import { retry } from "@/lib/utils";

const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

const tryFetchWithTokenRefresh = async (
  url: string,
  options: RequestInit,
  refreshUrl: string
): Promise<Response> => {
  let response = await fetch(url, options);

  if (response.status === 401) {
    // Intentar renovar el token
    const refreshResponse = await fetch(refreshUrl, {
      method: "POST",
      credentials: "include",
    });

    if (refreshResponse.ok) {
      // Token renovado, reintentar la solicitud original
      response = await fetch(url, options);
    } else {
      console.error("Failed to refresh token");
    }
  }

  return response;
};

export const apiFetch = async (url: string, options?: RequestInit) => {
  const headers = new Headers(options?.headers || {});

  if (!(options?.body instanceof FormData)) {
    headers.set("Content-Type", "application/json");
  }

  const response = await tryFetchWithTokenRefresh(
    `${BASE_URL}${url}`,
    {
      ...options,
      headers,
      credentials: "include",
    },
    `${BASE_URL}/api/users/refresh-token`
  );

  if (!response.ok) {
    // Intentar obtener el mensaje de error desde la respuesta
    let errorMessage = "API request failed";
    try {
      const errorData = await response.json();
      if (errorData.message) {
        errorMessage = errorData.message;
      }
    } catch (e) {
      // Si no se puede parsear el JSON, usar el estado HTTP como mensaje
      errorMessage = `Request failed with status ${response.status}`;
    }

    const error = new Error(errorMessage);
    (error as any).response = response;
    throw error;
  }

  return response;
};

export const createNotificacionForAction = async (
  userId: number,
  accionId: number,
  siniestroId: number,
  userRoleId?: number | null
) => {
  try {
    const response = await apiFetch("/api/notificaciones/accion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, accionId, siniestroId, userRoleId }),
    });

    if (!response.ok) {
      throw new Error("Failed to create notification for action");
    }

    // Opcionalmente, puedes manejar la respuesta aquí
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating notification for action:", error);
    throw error;
  }
};

export const deleteCoberturaForIncident = async (
  incidentId: number,
  coberturaId: number
) => {
  try {
    const response = await apiFetch(
      `/api/coberturas/incident/${incidentId}/${coberturaId}`,
      {
        method: "DELETE",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete cobertura for incident");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSiniestros = async (
  pageIndex: number = 0,
  pageSize: number = 10,
  insuredDNI?: string,
  companyId?: number,
  selectedTramitador?: string,
  siniestroNumber?: string | number,
  insuredName?: string,
  policyNumber?: string,
  sortBy: string = "ultimaAccionFecha",
  sortDirection: string = "DESC",
  selectedEstado?: string,
  selectedProveedor?: string,
  receiptNumber?: string,
  deliveryNumber?: string,
  electroReceiptNumber?: string
) => {
  const url = new URL(`/api/siniestros`, BASE_URL); // Cambia aquí

  url.searchParams.append("page", (pageIndex + 1).toString());
  url.searchParams.append("itemsPerPage", pageSize.toString());
  url.searchParams.append("sortBy", sortBy);
  url.searchParams.append("sortDirection", sortDirection);

  if (insuredDNI) url.searchParams.append("insuredDNI", insuredDNI);
  if (companyId !== undefined)
    url.searchParams.append("companyId", companyId.toString());
  if (selectedTramitador)
    url.searchParams.append("responsibleName", selectedTramitador);
  if (siniestroNumber)
    url.searchParams.append(
      "companyIncidentNumber",
      siniestroNumber.toString()
    );
  if (insuredName) url.searchParams.append("insuredName", insuredName);
  if (policyNumber) url.searchParams.append("policyNumber", policyNumber);
  if (selectedEstado) url.searchParams.append("stateId", selectedEstado);
  if (selectedProveedor)
    url.searchParams.append("proveedorName", selectedProveedor);
  if (receiptNumber) url.searchParams.append("receiptNumber", receiptNumber);
  if (deliveryNumber) url.searchParams.append("deliveryNumber", deliveryNumber);
  if (electroReceiptNumber)
    url.searchParams.append("electroReceiptNumber", electroReceiptNumber);

  try {
    const response = await apiFetch(url.pathname + url.search); // Aquí solo pasas la ruta relativa
    const data = await response.json();

    return {
      siniestros: data.siniestros,
      pageCount: Math.ceil(data.total / pageSize),
      total: data.total,
    };
  } catch (error) {
    throw error;
  }
};

export const fetchTramitadores = async () => {
  try {
    const response = await apiFetch("/api/users/tramitadores");
    const tramitadores = await response.json();
    return Array.isArray(tramitadores)
      ? tramitadores.sort((a, b) =>
          a.display_name.localeCompare(b.display_name)
        )
      : [];
  } catch (error) {
    throw error;
  }
};

export const fetchTramitadoresConAdminAlejandro = async () => {
  try {
    const response = await apiFetch(
      "/api/users/tramitadores-con-admin-alejandro"
    );
    const tramitadores = await response.json();
    return Array.isArray(tramitadores)
      ? tramitadores.sort((a, b) =>
          a.display_name.localeCompare(b.display_name)
        )
      : [];
  } catch (error) {
    throw error;
  }
};

export async function fetchProveedores(): Promise<Tramitador[]> {
  try {
    const response = await apiFetch("/api/users/tramitadores-id-11");
    const proveedores = await response.json();
    return Array.isArray(proveedores)
      ? proveedores.sort((a, b) => a.display_name.localeCompare(b.display_name))
      : [];
  } catch (error) {
    throw error;
  }
}

export const fetchBienesSiniestro = async (siniestroId: number) => {
  try {
    const response = await apiFetch(`/api/siniestros/${siniestroId}/bienes`);
    const data = await response.json();
    return data.bienes;
  } catch (error) {
    throw error;
  }
};

export const fetchBienById = async (bienId: number) => {
  try {
    const response = await apiFetch(`/api/bienes/${bienId}`);
    if (!response.ok) {
      throw new Error("Error al obtener los detalles del bien");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchHistorialSiniestro = async (siniestroId: number) => {
  try {
    const response = await apiFetch(`/api/siniestros/${siniestroId}/historial`);
    const data = await response.json();
    return data.historial;
  } catch (error) {
    throw error;
  }
};

export const fetchAgendaSiniestro = async (siniestroId: number) => {
  try {
    const response = await apiFetch(`/api/siniestros/${siniestroId}/agenda`);
    const data = await response.json();
    return data.agenda;
  } catch (error) {
    throw error;
  }
};

export const notifyAboutComment = async (
  siniestroId: number,
  commentMessage: string,
  commentatorId: number,
  isTramitador: boolean,
  notifyTramitador: boolean = true
) => {
  try {
    const response = await apiFetch(
      "/api/notificaciones/notify-about-comment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siniestroId,
          commentMessage,
          commentatorId,
          isTramitador,
          notifyTramitador,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to notify");
    }

    return await response.json();
  } catch (error) {
    console.error("Error notifying:", error);
    throw error;
  }
};

export const fetchSiniestrosOlderThan = async (
  months: number,
  companyId?: number
) => {
  try {
    let url = `/api/siniestros/older-than?months=${months}`;
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    const response = await apiFetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      `Error fetching siniestros older than ${months} months:`,
      error
    );
    throw error;
  }
};

export const checkIfUserIsTramitador = async (
  userId: number,
  siniestroId: number
): Promise<boolean> => {
  try {
    const response = await apiFetch(
      `/api/siniestros/${siniestroId}/tramitador/${userId}`
    );
    const data = await response.json();
    return data.isTramitador;
  } catch (error) {
    console.error("Error checking if user is tramitador:", error);
    throw error;
  }
};

export const addHistorialMessage = async (
  siniestroId: number,
  userId: number,
  text: string
) => {
  try {
    const body = JSON.stringify({ userId, text });

    const response = await apiFetch(
      `/api/siniestros/${siniestroId}/historial`,
      {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await apiFetch("/api/users/me");
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getSiniestroById = async (siniestroId: number) => {
  try {
    const response = await apiFetch(`/api/siniestros/${siniestroId}`);
    const data = await response.json();
    return data.siniestro;
  } catch (error) {
    throw error;
  }
};

export const updateBienResolucion = async (
  siniestroId: number,
  bienId: number,
  resolucionId: number
) => {
  try {
    const response = await apiFetch(
      `/api/siniestros/${siniestroId}/bienes/${bienId}/resolucion`,
      {
        method: "PATCH",
        body: JSON.stringify({ resolucionId }),
      }
    );

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateBienDetails = async (bienId: number, values: any) => {
  try {
    // Verificar si `item_type` está presente en los valores
    if (values.item_type) {
      const checkResponse = await apiFetch(`/api/bienes/tipos`, {
        method: "POST",
        body: JSON.stringify({ name: values.item_type }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const checkData = await checkResponse.json();

      if (checkResponse.ok) {
        // Si existe o se creó, usar el ID
        values.item_type_id = checkData.id;
      } else {
        throw new Error(
          checkData.message || "Error al verificar/crear tipo de bien"
        );
      }
    }

    // Actualizar el bien con el ID del tipo de bien
    const response = await apiFetch(`/api/bienes/${bienId}`, {
      method: "PATCH",
      body: JSON.stringify(values),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateBienObservations = async (
  bienId: number,
  observations: string
) => {
  try {
    const response = await apiFetch(`/api/bienes/${bienId}`, {
      method: "PATCH",
      body: JSON.stringify({ observations }),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateBienOtherObservations = async (
  bienId: number,
  otherObservations: string
) => {
  const fetchUpdate = async () => {
    const response = await apiFetch(`/api/bienes/${bienId}`, {
      method: "PATCH",
      body: JSON.stringify({ otherObservations: otherObservations }),
    });

    if (!response.ok) {
      throw new Error(
        `Error al actualizar las observaciones: ${response.statusText}`
      );
    }

    return await response.json();
  };

  try {
    return await retry(fetchUpdate, 3, 1000); // 3 reintentos con 1 segundo de retraso
  } catch (error) {
    console.error("Error en updateBienOtherObservations:", error);
    throw error;
  }
};

export const updateBienTipoPago = async (
  bienId: number,
  newTipoPagoId: number
) => {
  try {
    const response = await apiFetch(`/api/bienes/${bienId}`, {
      method: "PATCH",
      body: JSON.stringify({ item_payment_type: newTipoPagoId }),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchIngresosSiniestro = async (siniestroId: number) => {
  try {
    const response = await apiFetch(
      `/api/ingresos-siniestros/incident/${siniestroId}`
    );
    const data = await response.json();
    return data.ingresos;
  } catch (error) {
    throw error;
  }
};

export const updateIngresoSiniestro = async (
  ingresoId: number,
  updatedData: any
) => {
  try {
    const response = await apiFetch(`/api/ingresos-siniestros/${ingresoId}`, {
      method: "PATCH",
      body: JSON.stringify(updatedData),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const addIngresoSiniestro = async (
  siniestroId: number,
  ingresoData: any
) => {
  try {
    const response = await apiFetch(
      `/api/ingresos-siniestros/incident/${siniestroId}`,
      {
        method: "POST",
        body: JSON.stringify(ingresoData),
      }
    );

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteIngresoSiniestro = async (ingresoId: number) => {
  try {
    const response = await apiFetch(`/api/ingresos-siniestros/${ingresoId}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("Failed to delete ingreso");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchGastosSiniestro = async (siniestroId: number) => {
  try {
    const response = await apiFetch(
      `/api/gastos-siniestros/incident/${siniestroId}`
    );
    const data = await response.json();
    return data.gastos;
  } catch (error) {
    throw error;
  }
};

export const addGastoSiniestro = async (
  siniestroId: number,
  gastoData: any
) => {
  try {
    const response = await apiFetch(
      `/api/gastos-siniestros/incident/${siniestroId}`,
      {
        method: "POST",
        body: JSON.stringify(gastoData),
      }
    );

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateGastoSiniestro = async (
  gastoId: number,
  updatedData: any
) => {
  try {
    const response = await apiFetch(`/api/gastos-siniestros/${gastoId}`, {
      method: "PATCH",
      body: JSON.stringify(updatedData),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteGastoSiniestro = async (gastoId: number) => {
  try {
    const response = await apiFetch(`/api/gastos-siniestros/${gastoId}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("Failed to delete gasto");
    }
  } catch (error) {
    throw error;
  }
};

export const updateBienInstance = async (
  siniestroId: number,
  bienId: number,
  newInstanceId: number
) => {
  try {
    const response = await apiFetch(
      `/api/siniestros/${siniestroId}/bienes/${bienId}/instancia`,
      {
        method: "PATCH",
        body: JSON.stringify({ instanceId: newInstanceId }),
      }
    );

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchAttachmentsBySiniestroId = async (siniestroId: number) => {
  try {
    const response = await apiFetch(`/api/files/list/${siniestroId}`);
    const data = await response.json();
    return data.files;
  } catch (error) {
    throw error;
  }
};

export const uploadAttachment = async (
  incidentId: number,
  file: File
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiFetch(`/api/files/upload/${incidentId}`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("API request failed");
  }

  return await response.json();
};

export const deleteAttachment = async (key: string): Promise<void> => {
  try {
    // Extrae el relativePath de la URL
    const relativePath = key.replace(`${BASE_URL}/attachments/`, "");

    // Codifica el relativePath para manejar caracteres especiales
    const encodedPath = encodeURIComponent(relativePath);

    // Construye la URL correcta para la solicitud DELETE
    const response = await apiFetch(`/api/files/delete/${encodedPath}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAllAcciones = async () => {
  try {
    const response = await apiFetch("/api/siniestros/acciones");

    const data = await response.json();
    return data.acciones;
  } catch (error) {
    throw error;
  }
};

export const fetchAccionesBySiniestroId = async (siniestroId: number) => {
  try {
    const response = await apiFetch(`/api/siniestros/${siniestroId}/acciones`);

    const data = await response.json();
    return data.acciones;
  } catch (error) {
    throw error;
  }
};

export const fetchUsernames = async () => {
  try {
    const response = await apiFetch("/api/users/usernames");

    const data = await response.json();
    return data; // Devuelve solo los nombres de usuario
  } catch (error) {
    throw error;
  }
};

export const createBien = async (bienData: any) => {
  try {
    const response = await apiFetch(`/api/bienes`, {
      method: "POST",
      body: JSON.stringify(bienData),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const addAccionToSiniestro = async (
  siniestroId: number,
  idActionType: number,
  userAssignedId: number | null,
  itemId: number | null
) => {
  const body = JSON.stringify({
    idActionType,
    itemId,
    userAssignedId,
  });
  const response = await apiFetch(`/api/siniestros/${siniestroId}/acciones`, {
    method: "POST",
    body,
  });

  return await response.json();
};

export const updateSiniestroDetails = async (
  siniestroId: number,
  details: any
) => {
  try {
    const response = await apiFetch(`/api/siniestros/${siniestroId}/details`, {
      method: "PATCH",
      body: JSON.stringify(details),
    });

    if (response.status === 409) {
      const errorData = await response.json();
      throw new Error(
        errorData.message ||
          "Conflicto al actualizar los detalles del siniestro."
      );
    }

    if (!response.ok) {
      throw new Error("Error al actualizar los detalles del siniestro.");
    }

    const responseData = await response.json();
    return responseData.siniestro;
  } catch (error) {
    throw error;
  }
};

export const fetchProvincias = async () => {
  try {
    const response = await apiFetch("/api/provincias");
    if (!response.ok) {
      throw new Error("Error fetching provincias");
    }
    const provincias = await response.json();
    return provincias;
  } catch (error) {
    throw error;
  }
};

export const updateProvinceId = async (
  addressId: number,
  provinceId: number
) => {
  try {
    const response = await apiFetch(`/api/direcciones/${addressId}`, {
      method: "PATCH",
      body: JSON.stringify({ province_id: provinceId }),
    });

    if (!response.ok) {
      throw new Error("Error updating province_id");
    }

    const text = await response.text();
    return text ? JSON.parse(text) : {};
  } catch (error) {
    throw error;
  }
};

export const fetchCoberturas = async () => {
  try {
    const response = await apiFetch("/api/coberturas");
    return await response.json();
  } catch (error) {
    throw error;
  }
};

interface NewCoverage {
  coberturaId: number;
  sumaPoliza: number;
  observaciones: string;
  moneda: string;
}

export async function addCoverageToIncident(
  incidentId: number,
  newCoverage: NewCoverage
) {
  const response = await apiFetch(`/api/coberturas/incident/${incidentId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newCoverage),
  });

  if (!response.ok) {
    throw new Error("Error al agregar la cobertura");
  }

  const data = await response.json();
  return data;
}

export const fetchCoberturasForIncident = async (incidentId: number) => {
  try {
    const response = await apiFetch(`/api/coberturas/incident/${incidentId}`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateCoverageForIncident = async (
  incidentId: number,
  coberturaId: number,
  sumaPoliza: number,
  observaciones: string
) => {
  try {
    const response = await apiFetch(`/api/coberturas/incident/${incidentId}`, {
      method: "PATCH",
      body: JSON.stringify({ coberturaId, sumaPoliza, observaciones }),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const duplicateSiniestroAsGarantia = async (
  siniestroId: number,
  selectedBienes: number[]
) => {
  try {
    const url = `/api/siniestros/${siniestroId}/duplicate`;

    const response = await apiFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedBienes }),
    });

    if (!response.ok) {
      throw new Error("Error al duplicar el siniestro");
    }

    const data = await response.json();
    return data.siniestro; // Devuelve directamente el ID del siniestro duplicado
  } catch (error) {}
};

export const getOpenSiniestrosCount = async () => {
  try {
    const response = await apiFetch("/api/siniestros/open-siniestros");
    const data = await response.json();
    return {
      totalCount: data.siniestros.totalCount,
    };
  } catch (error) {
    throw error;
  }
};

export const getTodaySiniestrosCounts = async () => {
  try {
    const response = await apiFetch("/api/siniestros/today-siniestros-counts");
    const data = await response.json();
    return {
      countStateId8: data.siniestros.countStateId8,
      newSiniestros: data.siniestros.newSiniestros,
      closedToday: data.siniestros.closedToday,
    };
  } catch (error) {
    throw error;
  }
};

export const getMonthlySiniestrosCounts = async (
  year: number,
  month: number
) => {
  try {
    const response = await apiFetch(
      `/api/siniestros/monthly-siniestros-counts?year=${year}&month=${month}`
    );
    const data = await response.json();
    return {
      newMonthSiniestros: data.siniestros.newMonthSiniestros,
      closedMonth: data.siniestros.closedMonth,
    };
  } catch (error) {
    throw error;
  }
};

export const getAverageClosureDays = async () => {
  try {
    const response = await apiFetch("/api/siniestros/average-closure-days");
    const data = await response.json();
    return data.avgClosureDays;
  } catch (error) {
    throw error;
  }
};

export const getLast10DaysSiniestrosCountsByCompany = async () => {
  try {
    const response = await apiFetch(
      `/api/siniestros/last-10-days-counts-by-company`
    );
    return await response.json();
  } catch (error) {
    console.error(
      "Error fetching last 10 days siniestros counts by company:",
      error
    );
    throw error;
  }
};

export const getUserActivity = async (period = "today") => {
  try {
    let url = "/api/users/activity";

    if (period === "today") {
      url = "/api/users/activity/today"; // Endpoint para hoy
    } else if (period === "weekly") {
      url = "/api/users/activity/weekly"; // Endpoint para la semana
    }

    const response = await apiFetch(url);
    const data = await response.json();
    return data.activity; // Aquí asumimos que `data.activity` contiene el array de actividad
  } catch (error) {
    throw error;
  }
};

export const getUserActivityByDateRange = async (
  startDate: string,
  endDate: string
) => {
  try {
    const response = await apiFetch(
      `/api/users/activity/?startDate=${startDate}&endDate=${endDate}`
    );
    const data = await response.json();
    return data.activity;
  } catch (error) {
    throw error;
  }
};

export const getTodaySiniestrosCountsByCompany = async () => {
  try {
    const response = await apiFetch("/api/siniestros/today-counts-by-company");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      "Error fetching today's siniestros counts by company:",
      error
    );
    throw error;
  }
};

interface StatsParams {
  companyId?: number;
  itemTypeName?: string;
  startDate?: string;
  endDate?: string;
}

export const getSiniestrosStats = async (params: StatsParams) => {
  const queryParams = new URLSearchParams();
  if (params.companyId)
    queryParams.append("companyId", params.companyId.toString());
  if (params.itemTypeName)
    queryParams.append("itemTypeName", params.itemTypeName);
  if (params.startDate) queryParams.append("startDate", params.startDate);
  if (params.endDate) queryParams.append("endDate", params.endDate);

  return await apiFetch(`/api/siniestros/stats?${queryParams.toString()}`);
};

export const getItemTypes = async () => {
  try {
    const response = await apiFetch("/api/siniestros/item-types");
    const data = await response.json();
    return data.itemTypes;
  } catch (error) {
    throw error;
  }
};

export const getSiniestrosByBrand = async (params: StatsParams) => {
  const queryParams = new URLSearchParams();

  if (params.companyId) {
    queryParams.append("companyId", params.companyId.toString());
  }

  if (params.itemTypeName) {
    queryParams.append("itemTypeName", params.itemTypeName);
  }

  if (params.startDate) {
    queryParams.append("startDate", params.startDate);
  }

  if (params.endDate) {
    queryParams.append("endDate", params.endDate);
  }

  const url = `/api/siniestros/stats/by-brand?${queryParams.toString()}`;

  const response = await apiFetch(url);
  const data = await response.json();

  return data; // Esto devolverá el array de marcas con sus totales
};

export const getMonthlySiniestrosStats = async (
  params: StatsParams & { year: number }
) => {
  const queryParams = new URLSearchParams();
  if (params.companyId)
    queryParams.append("companyId", params.companyId.toString());
  if (params.itemTypeName)
    queryParams.append("itemTypeName", params.itemTypeName);
  if (params.year) queryParams.append("year", params.year.toString());

  return await apiFetch(
    `/api/siniestros/stats/monthly?${queryParams.toString()}`
  );
};

export const getCompanies = async () => {
  try {
    const response = await apiFetch("/api/companies");
    const data = await response.json();
    return data.companies.sort((a: { name: string }, b: { name: string }) =>
      a.name.localeCompare(b.name, "es", { sensitivity: "base" })
    );
  } catch (error) {
    throw error;
  }
};

export const searchAseguradoByDNI = async (
  documentType: string,
  documentNumber: string
) => {
  try {
    const response = await apiFetch(
      `/api/asegurados/documentType/${documentType}/documentNumber/${documentNumber}`
    );

    // Si llega aquí, la solicitud fue exitosa y podemos procesar la respuesta
    const { asegurado } = await response.json();
    return asegurado;
  } catch (error: any) {
    // Si el error tiene una respuesta y el estado es 404, devolvemos null
    if (error.response && error.response.status === 404) {
      console.info("Asegurado no encontrado, puedes crear uno nuevo.");
      return null;
    }

    // Re-lanzar otros errores
    console.error("Error al buscar asegurado:", error);
    throw error;
  }
};

export const getSegments = async () => {
  try {
    const response = await apiFetch("/api/siniestros/segments");
    const data = await response.json();
    return data.segments;
  } catch (error) {
    throw error;
  }
};

export const getSiniestrosByZipcode = async (filters: {
  companyId?: number;
  itemTypeName?: string;
  startDate?: string;
  endDate?: string;
}) => {
  try {
    const queryParams = new URLSearchParams();
    if (filters.companyId)
      queryParams.append("companyId", filters.companyId.toString());
    if (filters.itemTypeName)
      queryParams.append("itemTypeName", filters.itemTypeName);
    if (filters.startDate) queryParams.append("startDate", filters.startDate);
    if (filters.endDate) queryParams.append("endDate", filters.endDate);

    const response = await apiFetch(
      `/api/siniestros/stats/zipcode?${queryParams}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSiniestrosByFilters = async ({
  startDate,
  endDate,
  companyId,
  itemTypeName,
}: {
  startDate: string;
  endDate: string;
  companyId?: number | string;
  itemTypeName?: string;
}) => {
  try {
    // Asegurarnos de que companyId sea un número o undefined
    const numericCompanyId = companyId ? Number(companyId) : undefined;

    const params = new URLSearchParams();

    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    if (numericCompanyId)
      params.append("companyId", numericCompanyId.toString());
    if (itemTypeName) params.append("itemTypeName", itemTypeName);

    const response = await apiFetch(
      `/api/siniestros/stats/filtered?${params.toString()}`
    );

    if (!response.ok) {
      throw new Error("Error al obtener los siniestros");
    }

    return await response.json();
  } catch (error) {
    console.error("Error en getSiniestrosByFilters:", error);
    throw error;
  }
};

export const getPromedioCierreMensual = async (params: {
  companyId?: string;
  year?: string;
}) => {
  try {
    const queryParams = new URLSearchParams();

    // Solo agregar parámetros si existen y tienen valor
    if (params.companyId && params.companyId !== "Todos") {
      queryParams.append("companyId", params.companyId);
    }
    if (params.year) {
      queryParams.append("year", params.year);
    }

    const response = await apiFetch(
      `/api/siniestros/promedio-cierre${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`
    );

    return await response.json();
  } catch (error) {
    console.error("Error en getPromedioCierreMensual:", error);
    throw error;
  }
};

export const getSiniestrosAllianzStats = async (
  startDate?: string,
  endDate?: string,
  page: number = 1,
  limit: number = 20
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    params.append("page", page.toString());
    params.append("limit", limit.toString());

    const response = await apiFetch(`/api/siniestros/stats/allianz?${params}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Allianz stats:", error);
    throw error;
  }
};

export const getSiniestrosLifeStats = async (
  startDate?: string,
  endDate?: string,
  page: number = 1,
  limit: number = 20
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    params.append("page", page.toString());
    params.append("limit", limit.toString());

    const response = await apiFetch(
      `/api/siniestros/stats/life-seguros?${params}`
    );

    if (!response.ok) {
      throw new Error("Error al obtener las estadísticas de Life");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Life stats:", error);
    throw error;
  }
};

export const exportSiniestrosAllianzStats = async (
  startDate?: string,
  endDate?: string
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    const response = await apiFetch(
      `/api/siniestros/stats/allianz/export?${params}`,
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error al exportar las estadísticas");
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error exporting Allianz stats:", error);
    throw error;
  }
};

export const exportSiniestrosLifeStats = async (
  startDate?: string,
  endDate?: string
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    const response = await apiFetch(
      `/api/siniestros/stats/life-seguros/export?${params}`,
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error al exportar las estadísticas");
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error exporting Life stats:", error);
    throw error;
  }
};

export const getSiniestrosProvinciaStats = async (
  startDate?: string,
  endDate?: string,
  page: number = 1,
  limit: number = 20
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    params.append("page", page.toString());
    params.append("limit", limit.toString());

    const response = await apiFetch(
      `/api/siniestros/stats/provincia?${params}`
    );

    if (!response.ok) {
      throw new Error("Error al obtener las estadísticas de Provincia");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Provincia stats:", error);
    throw error;
  }
};

export const getSiniestrosRivadaviaStats = async (
  startDate?: string,
  endDate?: string,
  page: number = 1,
  limit: number = 20
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    params.append("page", page.toString());
    params.append("limit", limit.toString());

    const response = await apiFetch(
      `/api/siniestros/stats/rivadavia?${params}`
    );

    if (!response.ok) {
      throw new Error("Error al obtener las estadísticas de Rivadavia");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Rivadavia stats:", error);
    throw error;
  }
};

export const exportSiniestrosProvinciaStats = async (
  startDate?: string,
  endDate?: string
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    const response = await apiFetch(
      `/api/siniestros/stats/provincia/export?${params}`,
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error al exportar las estadísticas");
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error exporting Provincia stats:", error);
    throw error;
  }
};

export const exportSiniestrosRivadaviaStats = async (
  startDate?: string,
  endDate?: string
) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    const response = await apiFetch(
      `/api/siniestros/stats/rivadavia/export?${params}`,
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error al exportar las estadísticas");
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error exporting Rivadavia stats:", error);
    throw error;
  }
};
