import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAuth } from "@/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { loginUser } from "@/services/userService";
import { toast } from "sonner";
import { useTheme } from "../theme-provider";
import useLoadingStore from "@/stores/loadingStore";
import { ReloadIcon, EyeOpenIcon, EyeClosedIcon } from "@radix-ui/react-icons";
import darkBackgroundImage from "../../fondo-oscuro.webp";
import lightBackgroundImage from "../../fondo-claro.webp";
import { motion } from "framer-motion";

export default function HomePage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const { isSaving, startSaving, stopSaving } = useLoadingStore();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    startSaving();
    try {
      // Login en la app actual
      const data = await loginUser(username, password);
      login(data.username, data.isDisabled, data.roles, data.companyId);

      // Intento de login en la otra aplicación
      try {
        const loginUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3001/api/login"
            : "https://tuservicesrl.net/api/login";

        const response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
          credentials: "include",
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText);
        }
      } catch (error) {}

      navigate("/siniestros");
      toast.success("Bienvenido!");
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Ocurrió un error inesperado.");
      }
    } finally {
      stopSaving();
    }
  };

  const isDarkMode = theme === "dark";

  const darkModeClasses = {
    form: "bg-gray-700 bg-opacity-95 text-white",
    label: "text-gray-200",
    input: "bg-gray-700 text-white border-gray-600",
    errorText: "text-red-400",
    title: "text-white",
    subtitle: "text-gray-400",
  };

  const lightModeClasses = {
    form: "bg-white bg-opacity-95 text-gray-900",
    label: "text-gray-700",
    input: "bg-white text-gray-700 border-gray-300",
    errorText: "text-red-600",
    title: "text-gray-900",
    subtitle: "text-gray-700",
  };

  const modeClasses = isDarkMode ? darkModeClasses : lightModeClasses;

  return (
    <div
      className="h-screen flex justify-center items-center overflow-hidden"
      style={{
        backgroundImage: `url(${
          isDarkMode ? darkBackgroundImage : lightBackgroundImage
        })`,
        backgroundSize: "cover",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.7,
          ease: "easeOut",
          opacity: { duration: 0.8 },
          y: { duration: 0.5 },
        }}
        className="container h-full flex flex-col justify-center items-center"
      >
        <form
          className={`w-full md:w-[500px] p-8 rounded-xl shadow-md ${modeClasses.form}`}
          onSubmit={handleSubmit}
        >
          <h1
            className={`text-3xl font-semibold tracking-tight mb-4 ${modeClasses.title} text-center`}
          >
            Iniciar sesión
          </h1>
          <p className={`text-sm text-center mb-6 ${modeClasses.subtitle}`}>
            Ingresa tu nombre de usuario y contraseña para acceder.
          </p>
          <div className="space-y-4">
            <div className="flex flex-col items-center">
              <Label htmlFor="username" className={modeClasses.label}>
                Nombre de usuario
              </Label>
              <Input
                id="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                className={`w-[300px] mt-2 ${modeClasses.input}`}
                autoComplete="username"
              />
            </div>
            <div className="relative flex flex-col items-center">
              <Label htmlFor="password" className={modeClasses.label}>
                Contraseña
              </Label>
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className={`w-[300px] mt-2 pr-10 ${modeClasses.input}`}
                autoComplete="current-password"
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="mt-3 absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                {showPassword ? (
                  <EyeClosedIcon className="h-5 w-5" />
                ) : (
                  <EyeOpenIcon className="h-5 w-5" />
                )}
              </button>
            </div>

            {error != null && (
              <div className={modeClasses.errorText}>
                {error.split(", ").map((err, index) => (
                  <p key={index}>{err}</p>
                ))}
              </div>
            )}
          </div>
          <div className="mt-6 flex justify-center">
            <Button
              type="submit"
              className={`bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded ${
                isSaving ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSaving || username === "" || password === ""}
            >
              {isSaving ? (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  Iniciando sesión...
                </>
              ) : (
                "Iniciar sesión"
              )}
            </Button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}
