import { apiFetch } from "@/services/siniestroService";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface AuthContextType {
  username: string | null;
  roles: number[];
  isAuthenticated: boolean;
  isDisabled: boolean;
  isAuthChecked: boolean;
  companyId: number | null;
  login: (
    username: string,
    isDisabled: boolean,
    roles: number[],
    companyId: number | null
  ) => void;
  logout: () => void;
  checkAuth: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [username, setUsername] = useState<string | null>(null);
  const [roles, setRoles] = useState<number[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<number | null>(null);

  const login = (
    username: string,
    isDisabled: boolean,
    roles: number[],
    companyId: number | null
  ) => {
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("state");
    setUsername(username);
    setRoles(roles);
    setIsAuthenticated(!isDisabled);
    setIsDisabled(isDisabled);
    setCompanyId(companyId);
  };

  const logout = async () => {
    try {
      await apiFetch("/api/users/logout", {
        method: "POST",
        credentials: "include",
      });
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      localStorage.removeItem("state");
      setUsername(null);
      setRoles([]);
      setIsAuthenticated(false);
      setIsDisabled(false);
      setCompanyId(null);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const checkAuth = async () => {
    try {
      let response = await apiFetch("/api/users/check-auth", {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        setUsername(data.username);
        setRoles(data.roles || []);
        setIsAuthenticated(true);
        setIsDisabled(!data.state);
        setCompanyId(data.companyId || null);
      } else if (response.status === 401) {
        const refreshResponse = await apiFetch("/api/users/refresh-token", {
          method: "POST",
        });

        if (refreshResponse.ok) {
          response = await apiFetch("/api/users/check-auth", {
            method: "GET",
          });

          if (response.ok) {
            const data = await response.json();
            setUsername(data.username);
            setRoles(data.roles || []);
            setIsAuthenticated(true);
            setIsDisabled(!data.state);
            setCompanyId(data.companyId || null);
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsAuthChecked(true);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        username,
        roles,
        isAuthenticated,
        isDisabled,
        isAuthChecked,
        companyId,
        login,
        logout,
        checkAuth,
      }}
    >
      {isAuthChecked && children}
    </AuthContext.Provider>
  );
};
