import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarDays, Calendar, Filter } from "lucide-react";
import { FaFileExcel } from "react-icons/fa";

interface AllianzFilterProps {
  selectedStartYear: number;
  selectedStartMonth: number;
  selectedEndYear: number;
  selectedEndMonth: number;
  onStartYearChange: (year: string) => void;
  onStartMonthChange: (month: string) => void;
  onEndYearChange: (year: string) => void;
  onEndMonthChange: (month: string) => void;
  onPeriodChange: (period: string) => void;
  onApplyFilters: () => void;
  handleExport?: () => void;
}

export const AllianzFilter = ({
  selectedStartYear,
  selectedStartMonth,
  selectedEndYear,
  selectedEndMonth,
  onStartYearChange,
  onStartMonthChange,
  onEndYearChange,
  onEndMonthChange,
  onPeriodChange,
  onApplyFilters,
  handleExport,
}: AllianzFilterProps) => {
  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i
  );
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  // Función para validar si la fecha de inicio es posterior a la fecha de fin
  const isStartDateAfterEndDate = () => {
    if (selectedStartYear > selectedEndYear) return true;
    if (
      selectedStartYear === selectedEndYear &&
      selectedStartMonth > selectedEndMonth
    )
      return true;
    return false;
  };

  // Función para corregir la fecha de fin si es necesario
  const adjustEndDate = (startYear: number, startMonth: number) => {
    if (
      selectedEndYear < startYear ||
      (selectedEndYear === startYear && selectedEndMonth < startMonth)
    ) {
      onEndYearChange(startYear.toString());
      onEndMonthChange(startMonth.toString());
    }
  };

  // Función para corregir la fecha de inicio si es necesario
  const adjustStartDate = (endYear: number, endMonth: number) => {
    if (
      selectedStartYear > endYear ||
      (selectedStartYear === endYear && selectedStartMonth > endMonth)
    ) {
      onStartYearChange(endYear.toString());
      onStartMonthChange(endMonth.toString());
    }
  };

  const handleStartMonthChange = (month: string) => {
    onStartMonthChange(month);
    adjustEndDate(selectedStartYear, parseInt(month));
  };

  const handleStartYearChange = (year: string) => {
    onStartYearChange(year);
    adjustEndDate(parseInt(year), selectedStartMonth);
  };

  const handleEndMonthChange = (month: string) => {
    onEndMonthChange(month);
    adjustStartDate(selectedEndYear, parseInt(month));
  };

  const handleEndYearChange = (year: string) => {
    onEndYearChange(year);
    adjustStartDate(parseInt(year), selectedEndMonth);
  };

  const handlePeriodChange = (period: string) => {
    onPeriodChange(period);
  };

  return (
    <Card className="shadow-sm">
      <CardContent className="p-6">
        <div className="flex flex-col gap-6">
          {/* Primera fila: Filtros de fecha */}
          <div className="flex flex-col md:flex-row gap-6 items-start md:items-end">
            <div className="space-y-4 flex-1">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Desde */}
                <div className="space-y-2">
                  <label className="text-sm font-medium flex items-center gap-2 text-muted-foreground">
                    <CalendarDays className="h-4 w-4 text-blue-500" />
                    Desde
                  </label>
                  <div className="flex gap-2">
                    <Select
                      value={selectedStartMonth.toString()}
                      onValueChange={handleStartMonthChange}
                    >
                      <SelectTrigger className="w-[140px] border-gray-200">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {months.map((month) => (
                          <SelectItem key={month} value={month.toString()}>
                            {new Date(2000, month - 1).toLocaleString("es", {
                              month: "long",
                            })}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      value={selectedStartYear.toString()}
                      onValueChange={handleStartYearChange}
                    >
                      <SelectTrigger className="w-[100px] border-gray-200">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {years.map((year) => (
                          <SelectItem key={year} value={year.toString()}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                {/* Hasta */}
                <div className="space-y-2">
                  <label className="text-sm font-medium flex items-center gap-2 text-muted-foreground">
                    <Calendar className="h-4 w-4 text-blue-500" />
                    Hasta
                  </label>
                  <div className="flex gap-2">
                    <Select
                      value={selectedEndMonth.toString()}
                      onValueChange={handleEndMonthChange}
                    >
                      <SelectTrigger className="w-[140px] border-gray-200">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {months.map((month) => (
                          <SelectItem key={month} value={month.toString()}>
                            {new Date(2000, month - 1).toLocaleString("es", {
                              month: "long",
                            })}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      value={selectedEndYear.toString()}
                      onValueChange={handleEndYearChange}
                    >
                      <SelectTrigger className="w-[100px] border-gray-200">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {years.map((year) => (
                          <SelectItem key={year} value={year.toString()}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            </div>

            {/* Botones de acción */}
            <div className="flex gap-3 w-full md:w-auto">
              <Button
                onClick={onApplyFilters}
                className="flex-1 md:flex-none bg-blue-600 hover:bg-blue-700"
                size="sm"
                disabled={isStartDateAfterEndDate()}
              >
                <Filter className="mr-2 h-4 w-4" />
                Aplicar filtros
              </Button>
              {handleExport && (
                <Button
                  onClick={handleExport}
                  className="flex-1 md:flex-none bg-emerald-600 hover:bg-emerald-700"
                  size="sm"
                  disabled={isStartDateAfterEndDate()}
                >
                  <FaFileExcel className="mr-2 h-4 w-4" />
                  Exportar Excel
                </Button>
              )}
            </div>
          </div>

          {/* Segunda fila: Filtros rápidos */}
          <div className="flex flex-wrap gap-2">
            <Button
              variant="outline"
              onClick={() => handlePeriodChange("currentMonth")}
              size="sm"
              className="text-gray-600 hover:text-gray-800 border-gray-200"
            >
              Mes actual
            </Button>
            <Button
              variant="outline"
              onClick={() => handlePeriodChange("lastMonth")}
              size="sm"
              className="text-gray-600 hover:text-gray-800 border-gray-200"
            >
              Mes anterior
            </Button>
            <Button
              variant="outline"
              onClick={() => handlePeriodChange("currentYear")}
              size="sm"
              className="text-gray-600 hover:text-gray-800 border-gray-200"
            >
              Año actual
            </Button>
            <Button
              variant="outline"
              onClick={() => handlePeriodChange("lastYear")}
              size="sm"
              className="text-gray-600 hover:text-gray-800 border-gray-200"
            >
              Año anterior
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
