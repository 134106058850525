import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { apiFetch } from "@/services/siniestroService";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface LaCajaOrdenCompraDialogProps {
  siniestro: {
    companyIncidentNumber: string;
  };
  onClose: () => void;
}

const comercios = [
  "Frávega",
  "On City",
  "Musimundo",
  "Authogar",
  "Naldo",
] as const;

type Comercio = (typeof comercios)[number];

const LaCajaOrdenCompraDialog: React.FC<LaCajaOrdenCompraDialogProps> = ({
  siniestro,
  onClose,
}) => {
  const [monto, setMonto] = useState<string>("");
  const [fecha, setFecha] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [comercio, setComercio] = useState<Comercio | "">("");
  const [isLoading, setIsLoading] = useState(false);

  const handleExportPdf = async () => {
    setIsLoading(true);
    try {
      const response = await apiFetch("/api/pdf/lacaja-orden-compra", {
        method: "POST",
        body: JSON.stringify({
          siniestroNumber: siniestro.companyIncidentNumber,
          monto: parseFloat(monto),
          fecha: fecha,
          comercio: comercio,
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Orden_Compra_LaCaja_${siniestro.companyIncidentNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error exporting PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Generar orden de compra La Caja</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <Label>Monto de la orden de compra:</Label>
            <Input
              type="number"
              min="0"
              step="0.01"
              value={monto}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value === "" ||
                  (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0)
                ) {
                  setMonto(value);
                }
              }}
              placeholder="Ingrese el monto en pesos"
            />
          </div>
          <div>
            <Label>Fecha:</Label>
            <Input
              type="date"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
            />
          </div>
          <div>
            <Label>Comercio:</Label>
            <Select
              value={comercio}
              onValueChange={(value) => setComercio(value as Comercio)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleccione un comercio" />
              </SelectTrigger>
              <SelectContent>
                {comercios.map((c) => (
                  <SelectItem key={c} value={c}>
                    {c}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={handleExportPdf}
            disabled={
              isLoading ||
              !monto ||
              parseFloat(monto) <= 0 ||
              !fecha ||
              !comercio
            }
          >
            {isLoading ? (
              <>
                <AiOutlineLoading3Quarters className="mr-2 h-4 w-4 animate-spin" />
                Exportando...
              </>
            ) : (
              "Exportar PDF"
            )}
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LaCajaOrdenCompraDialog;
