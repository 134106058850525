import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { toast } from "sonner";
import {
  getTodaySiniestrosCounts,
  getOpenSiniestrosCount,
  getMonthlySiniestrosCounts,
  getAverageClosureDays,
  getUserActivity,
  getLast10DaysSiniestrosCountsByCompany,
  getTodaySiniestrosCountsByCompany,
  getUserActivityByDateRange,
} from "@/services/siniestroService";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { HiChartPie } from "react-icons/hi";
import { FaRegClock } from "react-icons/fa";
import { FaCircleExclamation } from "react-icons/fa6";
import { LuPlusCircle } from "react-icons/lu";
import NotFoundPage from "../pages/NotFoundPage";
import { useAuth } from "@/contexts/AuthContext";
import CombinedSiniestrosChart from "./CombinedSiniestrosChart";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";

interface UserActivity {
  username: string;
  display_name?: string;
  user_id: number;
  access_count: number;
  first_login_at: string;
  last_login_at: string;
  last_activity_at: string;
  last_ip: string;
  device_info: string;
  browser: string;
  operating_system: string;
  ip_location?: {
    country: string;
    city: string;
    regionName: string;
  };
}

const DashboardPage = () => {
  const [totalOpenSiniestros, setTotalOpenSiniestros] = useState<number>(0);
  const [totalStateId8, setTotalStateId8] = useState<number>(0);
  const [newSiniestros, setNewSiniestros] = useState<number>(0);
  const [userActivityToday, setUserActivityToday] = useState([]);
  const [userActivityWeekly, setUserActivityWeekly] = useState([]);
  const [closedToday, setClosedToday] = useState<number>(0);
  const [last10DaysData, setLast10DaysData] = useState([]);
  const [todaySiniestrosData, setTodaySiniestrosData] = useState([]);
  const [monthlyData, setMonthlyData] = useState<{
    newMonthSiniestros: number;
    closedMonth: number;
  }>({
    newMonthSiniestros: 0,
    closedMonth: 0,
  });
  const [averageClosureDays, setAverageClosureDays] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isHistoricalLoading, setIsHistoricalLoading] =
    useState<boolean>(false);
  const { roles } = useAuth();
  const hasRole2 = roles.includes(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState<string>("access_count");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const itemsPerPage = 10;
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [userActivityByDate, setUserActivityByDate] = useState<UserActivity[]>(
    []
  );
  const [historicalCurrentPage, setHistoricalCurrentPage] = useState(1);
  const [historicalSearchTerm, setHistoricalSearchTerm] = useState("");
  const [historicalSortColumn, setHistoricalSortColumn] =
    useState<string>("access_count");
  const [historicalSortDirection, setHistoricalSortDirection] = useState<
    "asc" | "desc"
  >("desc");

  if (!hasRole2) {
    return <NotFoundPage />;
  }

  const fetchSiniestrosCounts = async () => {
    try {
      setIsLoading(true);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const { totalCount } = await getOpenSiniestrosCount();
      const { countStateId8, newSiniestros, closedToday } =
        await getTodaySiniestrosCounts();
      const monthlyCounts = await getMonthlySiniestrosCounts(
        currentYear,
        currentMonth
      );
      const avgClosureDays = await getAverageClosureDays();
      const last10DaysData = await getLast10DaysSiniestrosCountsByCompany();
      const todayData = await getTodaySiniestrosCountsByCompany();
      setTodaySiniestrosData(todayData);
      setLast10DaysData(last10DaysData);

      let activityToday = await getUserActivity("today");
      let activityWeekly = await getUserActivity("weekly");

      // Ordenar por access_count de mayor a menor
      activityToday = activityToday.sort(
        (a: UserActivity, b: UserActivity) => b.access_count - a.access_count
      );
      activityWeekly = activityWeekly.sort(
        (a: UserActivity, b: UserActivity) => b.access_count - a.access_count
      );

      setTotalOpenSiniestros(totalCount);
      setTotalStateId8(countStateId8);
      setNewSiniestros(newSiniestros);
      setClosedToday(closedToday);
      setMonthlyData(monthlyCounts);
      setAverageClosureDays(avgClosureDays);
      setUserActivityToday(activityToday);
      setUserActivityWeekly(activityWeekly);
    } catch (error) {
      toast.error("Error al obtener los siniestros");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = async (date: Date | undefined) => {
    if (!date) return;
    try {
      setIsHistoricalLoading(true);
      const formattedDate = format(date, "yyyy-MM-dd");
      const activityData = await getUserActivityByDateRange(
        formattedDate,
        formattedDate
      );
      setUserActivityByDate(activityData);
      setSelectedDate(date);
      setHistoricalCurrentPage(1);
    } catch (error) {
      toast.error(
        "Error al obtener la actividad de usuarios para la fecha seleccionada"
      );
    } finally {
      setIsHistoricalLoading(false);
    }
  };

  useEffect(() => {
    fetchSiniestrosCounts();
    // Cargar datos históricos para la fecha actual
    const loadInitialHistoricalData = async () => {
      try {
        setIsHistoricalLoading(true);
        const formattedDate = format(selectedDate, "yyyy-MM-dd");
        const activityData = await getUserActivityByDateRange(
          formattedDate,
          formattedDate
        );
        setUserActivityByDate(activityData);
      } catch (error) {
        toast.error(
          "Error al obtener la actividad de usuarios para la fecha seleccionada"
        );
      } finally {
        setIsHistoricalLoading(false);
      }
    };

    loadInitialHistoricalData();
  }, []); // Solo se ejecuta al montar el componente

  const getMonthName = (month: number): string => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return months[month - 1];
  };

  const pieChartData = [
    {
      name: "Nuevos",
      value: monthlyData.newMonthSiniestros,
      fill: "hsl(var(--chart-1))",
    },
    {
      name: "Cerrados",
      value: monthlyData.closedMonth,
      fill: "hsl(var(--chart-2))",
    },
  ];

  const todayPieChartData = [
    {
      name: "Nuevos",
      value: newSiniestros,
      fill: "hsl(var(--chart-3))",
    },
    {
      name: "Cerrados",
      value: closedToday,
      fill: "hsl(var(--chart-4))",
    },
  ];

  const chartConfig = {
    newMonthSiniestros: {
      label: "Nuevos Siniestros",
      color: "var(--chart-1)",
    },
    closedMonth: {
      label: "Siniestros Cerrados",
      color: "var(--chart-2)",
    },
    newSiniestros: {
      label: "Nuevos Hoy",
      color: "var(--chart-3)",
    },
    closedToday: {
      label: "Cerrados Hoy",
      color: "var(--chart-4)",
    },
  } satisfies ChartConfig;

  const getTimeFromTimestamp = (timestamp: string) => {
    return timestamp.split("T")[1].slice(0, 5); // Solo toma HH:MM
  };

  // Función para filtrar y ordenar usuarios
  const getFilteredUsers = () => {
    const filtered = userActivityToday.filter((user: UserActivity) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filtered.sort((a: UserActivity, b: UserActivity) => {
      const aValue = a[sortColumn as keyof UserActivity];
      const bValue = b[sortColumn as keyof UserActivity];

      // Manejar casos undefined o null
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Manejar ordenamiento especial para fechas
      if (
        sortColumn === "first_login_at" ||
        sortColumn === "last_login_at" ||
        sortColumn === "last_activity_at"
      ) {
        const dateA = new Date(aValue as string);
        const dateB = new Date(bValue as string);
        return sortDirection === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      // Ordenamiento normal para otros campos
      if (sortDirection === "asc") {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    });

    return filtered;
  };

  // Calcular páginas
  const filteredUsers = getFilteredUsers();
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Función para cambiar el ordenamiento
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  // Función para filtrar y ordenar usuarios históricos
  const getFilteredHistoricalUsers = () => {
    const filtered = userActivityByDate.filter((user: UserActivity) =>
      user.username.toLowerCase().includes(historicalSearchTerm.toLowerCase())
    );

    filtered.sort((a: UserActivity, b: UserActivity) => {
      const aValue = a[historicalSortColumn as keyof UserActivity];
      const bValue = b[historicalSortColumn as keyof UserActivity];

      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      if (
        historicalSortColumn === "first_login_at" ||
        historicalSortColumn === "last_login_at" ||
        historicalSortColumn === "last_activity_at"
      ) {
        const dateA = new Date(aValue as string);
        const dateB = new Date(bValue as string);
        return historicalSortDirection === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      if (historicalSortDirection === "asc") {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    });

    return filtered;
  };

  const handleHistoricalSort = (column: string) => {
    if (historicalSortColumn === column) {
      setHistoricalSortDirection(
        historicalSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setHistoricalSortColumn(column);
      setHistoricalSortDirection("desc");
    }
  };

  // Calcular páginas para datos históricos
  const filteredHistoricalUsers = getFilteredHistoricalUsers();
  const historicalTotalPages = Math.ceil(
    filteredHistoricalUsers.length / itemsPerPage
  );
  const currentHistoricalUsers = filteredHistoricalUsers.slice(
    (historicalCurrentPage - 1) * itemsPerPage,
    historicalCurrentPage * itemsPerPage
  );

  return (
    <div className="p-4 space-y-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Dashboard</h1>
      </div>

      <Card className="col-span-1 lg:col-span-4">
        <CardHeader className="items-center pb-2">
          <CardTitle className="flex items-center space-x-2 text-xl">
            <HiChartPie className="h-6 w-6 text-purple-500" />
            <span>Detalle de Actividad de Usuarios - Hoy</span>
          </CardTitle>
          <CardDescription>
            Registro de actividad de usuarios en el sistema
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="h-[200px] w-full" />
          ) : (
            <>
              <div className="mb-4 flex justify-between items-center">
                <input
                  type="text"
                  placeholder="Buscar usuario..."
                  className="px-3 py-2 border rounded-md"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="flex gap-2">
                  <button
                    onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                    disabled={currentPage === 1}
                    className="px-3 py-1 border rounded-md disabled:opacity-50"
                  >
                    Anterior
                  </button>
                  <span className="px-3 py-1">
                    Página {currentPage} de {totalPages}
                  </span>
                  <button
                    onClick={() =>
                      setCurrentPage(Math.min(totalPages, currentPage + 1))
                    }
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 border rounded-md disabled:opacity-50"
                  >
                    Siguiente
                  </button>
                </div>
              </div>

              <div className="overflow-x-auto rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow className="bg-muted/50">
                      <TableHead
                        className="font-semibold cursor-pointer"
                        onClick={() => handleSort("username")}
                      >
                        Usuario{" "}
                        {sortColumn === "username" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold cursor-pointer"
                        onClick={() => handleSort("display_name")}
                      >
                        Nombre Completo{" "}
                        {sortColumn === "display_name" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleSort("access_count")}
                      >
                        Interacciones{" "}
                        {sortColumn === "access_count" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleSort("first_login_at")}
                      >
                        Primer Login{" "}
                        {sortColumn === "first_login_at" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleSort("last_login_at")}
                      >
                        Último Login{" "}
                        {sortColumn === "last_login_at" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleSort("last_activity_at")}
                      >
                        Última Actividad{" "}
                        {sortColumn === "last_activity_at" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead className="font-semibold">IP</TableHead>
                      <TableHead className="font-semibold">Ubicación</TableHead>
                      <TableHead className="font-semibold">
                        Dispositivo
                      </TableHead>
                      <TableHead className="font-semibold">Navegador</TableHead>
                      <TableHead className="font-semibold">
                        Sistema Operativo
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentUsers.map(
                      (activity: UserActivity, index: number) => (
                        <TableRow
                          key={index}
                          className="hover:bg-muted/50 transition-colors"
                        >
                          <TableCell className="font-medium">
                            {activity.username}
                          </TableCell>
                          <TableCell className="font-medium">
                            {activity.display_name &&
                            activity.display_name !== activity.username
                              ? activity.display_name
                              : "-"}
                          </TableCell>
                          <TableCell className="text-center">
                            {activity.access_count}
                          </TableCell>
                          <TableCell className="text-center font-mono text-sm">
                            {getTimeFromTimestamp(activity.first_login_at)}
                          </TableCell>
                          <TableCell className="text-center font-mono text-sm">
                            {getTimeFromTimestamp(activity.last_login_at)}
                          </TableCell>
                          <TableCell className="text-center font-mono text-sm">
                            {getTimeFromTimestamp(activity.last_activity_at)}
                          </TableCell>
                          <TableCell className="font-mono text-sm">
                            {activity.last_ip}
                          </TableCell>
                          <TableCell>
                            {activity.ip_location
                              ? `${activity.ip_location.city}, ${activity.ip_location.regionName}, ${activity.ip_location.country}`
                              : "N/A"}
                          </TableCell>
                          <TableCell>{activity.device_info}</TableCell>
                          <TableCell>{activity.browser}</TableCell>
                          <TableCell>{activity.operating_system}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </CardContent>
      </Card>

      <Card className="col-span-1 lg:col-span-4">
        <CardHeader className="items-center pb-2">
          <CardTitle className="flex items-center space-x-2 text-xl">
            <HiChartPie className="h-6 w-6 text-purple-500" />
            <span>Detalle de Actividad de Usuarios - Por Día</span>
          </CardTitle>
          <CardDescription>
            Registro histórico de actividad de usuarios en el sistema
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isHistoricalLoading ? (
            <Skeleton className="h-[200px] w-full" />
          ) : (
            <>
              <div className="mb-4 flex justify-between items-center">
                <div className="flex gap-4 items-center">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] justify-start text-left font-normal",
                          !selectedDate && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {selectedDate ? (
                          format(selectedDate, "PPP", { locale: es })
                        ) : (
                          <span>Seleccionar fecha</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={selectedDate}
                        onSelect={handleDateChange}
                        initialFocus
                        locale={es}
                      />
                    </PopoverContent>
                  </Popover>
                  <input
                    type="text"
                    placeholder="Buscar usuario..."
                    className="px-3 py-2 border rounded-md"
                    value={historicalSearchTerm}
                    onChange={(e) => setHistoricalSearchTerm(e.target.value)}
                  />
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() =>
                      setHistoricalCurrentPage(
                        Math.max(1, historicalCurrentPage - 1)
                      )
                    }
                    disabled={historicalCurrentPage === 1}
                    className="px-3 py-1 border rounded-md disabled:opacity-50"
                  >
                    Anterior
                  </button>
                  <span className="px-3 py-1">
                    Página {historicalCurrentPage} de {historicalTotalPages}
                  </span>
                  <button
                    onClick={() =>
                      setHistoricalCurrentPage(
                        Math.min(
                          historicalTotalPages,
                          historicalCurrentPage + 1
                        )
                      )
                    }
                    disabled={historicalCurrentPage === historicalTotalPages}
                    className="px-3 py-1 border rounded-md disabled:opacity-50"
                  >
                    Siguiente
                  </button>
                </div>
              </div>

              <div className="overflow-x-auto rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow className="bg-muted/50">
                      <TableHead
                        className="font-semibold cursor-pointer"
                        onClick={() => handleHistoricalSort("username")}
                      >
                        Usuario{" "}
                        {historicalSortColumn === "username" &&
                          (historicalSortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold cursor-pointer"
                        onClick={() => handleHistoricalSort("display_name")}
                      >
                        Nombre Completo{" "}
                        {historicalSortColumn === "display_name" &&
                          (historicalSortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleHistoricalSort("access_count")}
                      >
                        Interacciones{" "}
                        {historicalSortColumn === "access_count" &&
                          (historicalSortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleHistoricalSort("first_login_at")}
                      >
                        Primer Login{" "}
                        {historicalSortColumn === "first_login_at" &&
                          (historicalSortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleHistoricalSort("last_login_at")}
                      >
                        Último Login{" "}
                        {historicalSortColumn === "last_login_at" &&
                          (historicalSortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead
                        className="font-semibold text-center cursor-pointer"
                        onClick={() => handleHistoricalSort("last_activity_at")}
                      >
                        Última Actividad{" "}
                        {historicalSortColumn === "last_activity_at" &&
                          (historicalSortDirection === "asc" ? "↑" : "↓")}
                      </TableHead>
                      <TableHead className="font-semibold">IP</TableHead>
                      <TableHead className="font-semibold">Ubicación</TableHead>
                      <TableHead className="font-semibold">
                        Dispositivo
                      </TableHead>
                      <TableHead className="font-semibold">Navegador</TableHead>
                      <TableHead className="font-semibold">
                        Sistema Operativo
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentHistoricalUsers.map(
                      (activity: UserActivity, index: number) => (
                        <TableRow
                          key={index}
                          className="hover:bg-muted/50 transition-colors"
                        >
                          <TableCell className="font-medium">
                            {activity.username}
                          </TableCell>
                          <TableCell className="font-medium">
                            {activity.display_name &&
                            activity.display_name !== activity.username
                              ? activity.display_name
                              : "-"}
                          </TableCell>
                          <TableCell className="text-center">
                            {activity.access_count}
                          </TableCell>
                          <TableCell className="text-center font-mono text-sm">
                            {getTimeFromTimestamp(activity.first_login_at)}
                          </TableCell>
                          <TableCell className="text-center font-mono text-sm">
                            {getTimeFromTimestamp(activity.last_login_at)}
                          </TableCell>
                          <TableCell className="text-center font-mono text-sm">
                            {getTimeFromTimestamp(activity.last_activity_at)}
                          </TableCell>
                          <TableCell className="font-mono text-sm">
                            {activity.last_ip}
                          </TableCell>
                          <TableCell>
                            {activity.ip_location
                              ? `${activity.ip_location.city}, ${activity.ip_location.regionName}, ${activity.ip_location.country}`
                              : "N/A"}
                          </TableCell>
                          <TableCell>{activity.device_info}</TableCell>
                          <TableCell>{activity.browser}</TableCell>
                          <TableCell>{activity.operating_system}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {isLoading ? (
          Array(4)
            .fill(null)
            .map((_, index) => (
              <Card key={index}>
                <CardHeader>
                  <CardTitle>
                    <Skeleton className="h-6 w-1/2" />
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <Skeleton className="h-12 w-3/4" />
                </CardContent>
              </Card>
            ))
        ) : (
          <>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <FaCircleExclamation className="h-6 w-6 text-blue-500" />
                  <span>Total siniestros abiertos (reabiertos)</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex items-center justify-center">
                <p className="text-2xl font-bold">
                  {totalOpenSiniestros} ({totalStateId8})
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <LuPlusCircle className="h-6 w-6 text-green-500" />
                  <span>
                    Nuevos y cerrados hoy (
                    {new Date().toLocaleDateString("es-AR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                    )
                  </span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex justify-between items-center">
                <div>
                  <p className="text-lg font-semibold">Nuevos:</p>
                  <p className="text-2xl font-bold">{newSiniestros}</p>
                </div>
                <div>
                  <p className="text-lg font-semibold">Cerrados:</p>
                  <p className="text-2xl font-bold">{closedToday}</p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <LuPlusCircle className="h-6 w-6 text-green-500" />
                  <span>
                    Siniestros este mes (
                    {getMonthName(new Date().getMonth() + 1)})
                  </span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex justify-between">
                <div>
                  <p className="text-lg font-semibold">Nuevos:</p>
                  <p className="text-2xl font-bold">
                    {monthlyData.newMonthSiniestros}
                  </p>
                </div>
                <div>
                  <p className="text-lg font-semibold">Cerrados:</p>
                  <p className="text-2xl font-bold">
                    {monthlyData.closedMonth}
                  </p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <FaRegClock className="h-6 w-6 text-red-500" />
                  <span>Tiempo promedio de cierre de siniestros</span>
                </CardTitle>
                <CardDescription>
                  Últimos 45 días - no se cuentan telefónicos
                </CardDescription>
              </CardHeader>
              <CardContent className="flex items-center justify-center">
                {isLoading ? (
                  <Skeleton className="h-12 w-3/4" />
                ) : (
                  <p className="text-2xl font-bold">
                    {averageClosureDays !== null
                      ? `${averageClosureDays} días`
                      : "N/A"}
                  </p>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </div>

      <CombinedSiniestrosChart
        last10DaysData={last10DaysData}
        todaySiniestrosData={todaySiniestrosData}
        isLoading={isLoading}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card className="col-span-1 lg:col-span-4">
          <CardHeader className="items-center pb-0">
            <CardTitle className="flex items-center space-x-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              <span>Gráfico de siniestros</span>
            </CardTitle>
            <CardDescription>
              Mostrando siniestros nuevos y cerrados por mes y hoy
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col lg:flex-row justify-between pb-0">
            <div className="flex-1">
              <h2 className="text-lg font-semibold mb-2 text-center">
                Siniestros del Mes ({getMonthName(new Date().getMonth() + 1)})
              </h2>
              {isLoading ? (
                <div className="flex justify-center items-center h-full">
                  <Skeleton className="h-[250px] w-full" />
                </div>
              ) : (
                <ChartContainer
                  config={chartConfig}
                  className="mx-auto aspect-square max-h-[250px]"
                >
                  <PieChart>
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie
                      data={pieChartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius={60}
                    />
                  </PieChart>
                </ChartContainer>
              )}
            </div>
            <div className="flex-1">
              <h2 className="text-lg font-semibold mb-2 text-center">
                Siniestros de hoy{" "}
                {new Date().toLocaleDateString("es-AR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </h2>
              {isLoading ? (
                <div className="flex justify-center items-center h-full">
                  <Skeleton className="h-[250px] w-full" />
                </div>
              ) : (
                <ChartContainer
                  config={chartConfig}
                  className="mx-auto aspect-square max-h-[250px]"
                >
                  <PieChart>
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie
                      data={todayPieChartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius={60}
                    />
                  </PieChart>
                </ChartContainer>
              )}
            </div>
          </CardContent>
          <CardFooter className="flex-col gap-2 text-sm">
            <div className="leading-none text-muted-foreground">
              Mostrando total de siniestros para el último mes seleccionado y
              hoy
            </div>
          </CardFooter>
        </Card>

        <Card className="col-span-1 lg:col-span-4">
          <CardHeader className="items-center pb-0">
            <CardTitle className="flex items-center space-x-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              <span>Actividad de Usuarios - Hoy</span>
            </CardTitle>
            <CardDescription>
              Mostrando la actividad de los usuarios del día.
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-[250px] w-full" />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={userActivityToday}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="username" />
                  <YAxis />
                  <Tooltip
                    formatter={(value, _name, props) => {
                      const activity = props.payload;
                      const formatTime = (dateStr: string) => {
                        const utcDate = new Date(dateStr);
                        const hours = utcDate.getUTCHours();
                        const minutes = utcDate.getUTCMinutes();
                        return `${hours.toString().padStart(2, "0")}:${minutes
                          .toString()
                          .padStart(2, "0")}`;
                      };

                      return [
                        <div>
                          <p>Usuario: {activity.username}</p>
                          {activity.display_name &&
                            activity.display_name !== activity.username && (
                              <p>Nombre: {activity.display_name}</p>
                            )}
                          <p>Interacciones: {value}</p>
                          <p>
                            Primer inicio del día:{" "}
                            {formatTime(activity.first_login_at)}
                          </p>
                          <p>
                            Última actividad:{" "}
                            {formatTime(activity.last_activity_at)}
                          </p>
                          <p>IP: {activity.last_ip}</p>
                          {activity.ip_location && (
                            <p>
                              Ubicación: {activity.ip_location.city},{" "}
                              {activity.ip_location.regionName},{" "}
                              {activity.ip_location.country}
                            </p>
                          )}
                          <p>Dispositivo: {activity.device_info}</p>
                          <p>Navegador: {activity.browser}</p>
                          <p>Sistema: {activity.operating_system}</p>
                        </div>,
                      ];
                    }}
                    labelFormatter={(label) => `Usuario: ${label}`}
                  />
                  <Legend />
                  <Bar
                    dataKey="access_count"
                    fill="#8884d8"
                    name="Interacciones"
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1 lg:col-span-4">
          <CardHeader className="items-center pb-0">
            <CardTitle className="flex items-center space-x-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              <span>Actividad de Usuarios - esta semana</span>
            </CardTitle>
            <CardDescription>
              Mostrando la actividad de los usuarios de la semana.
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-[250px] w-full" />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={userActivityWeekly}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="username" />
                  <YAxis />
                  <Tooltip
                    formatter={(value, _name, props) => {
                      const activity = props.payload;
                      const formatDate = (dateStr: string) => {
                        const utcDate = new Date(dateStr);
                        const day = utcDate
                          .getUTCDate()
                          .toString()
                          .padStart(2, "0");
                        const month = (utcDate.getUTCMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const year = utcDate.getUTCFullYear();
                        const hours = utcDate
                          .getUTCHours()
                          .toString()
                          .padStart(2, "0");
                        const minutes = utcDate
                          .getUTCMinutes()
                          .toString()
                          .padStart(2, "0");

                        return `${day}/${month}/${year} ${hours}:${minutes}`;
                      };

                      return [
                        <div>
                          <p>Total interacciones: {value}</p>
                          <p>
                            Primera conexión:{" "}
                            {formatDate(activity.first_login_week)}
                          </p>
                          <p>
                            Última actividad:{" "}
                            {formatDate(activity.last_activity_week)}
                          </p>
                          <p>IPs utilizadas: {activity.used_ips}</p>
                          {activity.ip_location && (
                            <p>
                              Ubicación última IP: {activity.ip_location.city},{" "}
                              {activity.ip_location.regionName},{" "}
                              {activity.ip_location.country}
                            </p>
                          )}
                          <p>Dispositivo: {activity.device_info}</p>
                          <p>Navegador: {activity.browser}</p>
                          <p>Sistema: {activity.operating_system}</p>
                        </div>,
                      ];
                    }}
                    labelFormatter={(label) => `Usuario: ${label}`}
                  />
                  <Legend />
                  <Bar
                    dataKey="total_access_count"
                    fill="#82ca9d"
                    name="Interacciones"
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DashboardPage;
